/* global Vue */
import { createApp } from 'vue';
import App from './components/App';
import InstantSearch from 'vue-instantsearch/vue3/es';

document.querySelectorAll('.site-search-app--extended-search').forEach(objElement => {
    const app = createApp({
        el: objElement,
        components: {
            App, // TODO - controleren of we keys via component props kunnen meegeven
        },
        siteSearchIndexConfig: Page.get('sitesearch_index_config_extended'),
        siteSearchTranslations: Page.get('sitesearch_translations_extended'),
    });
    app.use(InstantSearch);
    app.mount(objElement);
});
