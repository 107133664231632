import { ProductViewer } from './ProductViewer';

export class ProductViewersManager {
    constructor() {
        this.scan(false);
        this.productViewerCanvas = undefined;
        this.productViewerImages = undefined;
        this.productViewer = undefined;
    }

    scan(doInit, drawMode) {
        let self = this;
        document.querySelectorAll('.product-viewer').forEach(function (productViewerElement) {

            self.productViewerCanvas = productViewerElement.getElementsByClassName('product-viewer-canvas');
            self.productViewerImages = productViewerElement.getElementsByClassName('product-viewer-image');
            self.productViewer = new ProductViewer(productViewerElement, self.productViewerCanvas[0], self.productViewerImages[0]);

            if (doInit) {
                self.productViewer.initAll(drawMode);
            }
        });
    }

    rescan(drawMode) {
        let productViewerImages = document.getElementsByClassName('product-viewer-image');
        if (productViewerImages[0] !== undefined) productViewerImages[0].style.opacity = '1';
        let productViewerCanvas = document.getElementsByClassName('product-viewer-canvas');
        if (productViewerCanvas[0] !== undefined) productViewerCanvas[0].style.opacity = '0';

        setTimeout(() => {
            this.scan(true, drawMode);
        }, 500);
    }

    /**
     * Voor het collectie overzicht een product openen
     * en als er al een product open is, deze sluiten en het nieuwe product openen
     * maar met 1 instance van de product viewer
     * @param productNumber
     */
    reOpenProduct(productNumber) {
        let self = this;

        if (window.productViewersManager.productViewerCanvas === undefined) {
            setTimeout(() => {
                let lastProductViewerElement = undefined;
                document.querySelectorAll('.product-viewer').forEach(function (productViewerElement){
                    lastProductViewerElement = productViewerElement;
                });
                if (lastProductViewerElement !== undefined) {
                    window.productViewersManager = self;
                    window.productViewersManager.productViewerCanvas = lastProductViewerElement.getElementsByClassName('product-viewer-canvas');
                    window.productViewersManager.productViewerImages = lastProductViewerElement.getElementsByClassName('product-viewer-image');
                    window.productViewersManager.productViewer = new ProductViewer(lastProductViewerElement, window.productViewersManager.productViewerCanvas[0], window.productViewersManager.productViewerImages[0]);

                    window.productViewersManager.productViewer.initAll('product');
                }
            }, 500);
        } else {

            if (window.productViewersManager.productViewerImages !== undefined) {
                window.productViewersManager.productViewerImages[0].style.opacity = '1';
                window.productViewersManager.productViewerCanvas[0].style.opacity = '0';

                window.productViewersManager.productViewer.loadBrick(productNumber);
            }
        }
    }
}
