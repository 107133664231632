import * as THREE from 'three';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export class ProductViewer {
    constructor(productViewersElement, productViewerCanvas, productViewerImage) {

        this.debugMode = false;

        this.scene = undefined; // Three.js Scene
        this.camera = undefined; // Three.js Camera
        this.renderer = undefined; // Three.js Renderer

        this.lights = {};
        this.lights.light = undefined;
        this.lights.lightb = undefined;
        this.lights.lightc = undefined;
        this.lights.lighta = undefined;

        this.frameLengthMS = 1000 / 50; // 50fps
        this.previousTime = 0;
        this.hasScrolled = false;

        this.domInfo = {}; // Alle info uit de DOM bij elkaar
        this.domInfo.canvas = productViewerCanvas; // DOM canvas element
        this.domInfo.container = productViewersElement; // De div om het canvas heen
        this.domInfo.productViewerImage = productViewerImage; // Dummy / loading image
        this.domInfo.containerWidth = 500; // Breedte van DOM element
        this.domInfo.containerHeight = 500; // Hoogte van DOM element
        this.domInfo.containerLeft = 0; // Left van DOM element
        this.domInfo.containerTop = 0; // Top van DOM element

        this.domInfo.mobileMode = false; // Is dit een mobiel device
        this.domInfo.deviceOrientation = {}; // Draaiing van mobiel device
        this.domInfo.deviceOrientation.beta = 0;
        this.domInfo.deviceOrientation.gamma = 0;
        this.domInfo.deviceOrientation.alpha = 0;

        this.modelGroup = new THREE.Group();
        this.logoPlane = new THREE.Group();

        this.manager = new THREE.LoadingManager(); // Three.js manager: houdt bij of alle texturen zijn geladen
        this.loader = new THREE.TextureLoader(this.manager).setPath(''); // Three.js loader: laadt texturen
        this.brickTexturePath = '/application/files/rodruza/products/brickTextures';
        this.brickSpriteTexturePath = '/application/files/rodruza/products/brickTextureSprites';
        this.brickTextureQuality = '1024';
        this.brickTextureBaseCount = 36;
        this.mortelTexturePath = '/application/files/rodruza/products/mortelTextures';
        this.exportsPath = '/application/files/rodruza/products/exports';

        this.loadingStatus = 'init';
        this.drawMode = 'normal';
        this.loadMode = 'normal';
        this.loadRetrys = 0;
        this.inView = false;

        this.doDrawBricks = 0;
        this.isWorking = false;
        this.BrickArrayLeft = [];
        this.BrickArrayRight = [];
        this.BrickArrayMaxX = 0;
        this.BrickArrayMaxY = 0;

        this.modelRotationLock = false;
        this.modelRotationLockMode = 'free';
        this.modelRotationLockX = -65;
        this.modelRotationLockY = 0;
        this.modelRotationX = -65;
        this.modelRotationXto = -45 + 15;
        this.modelRotationY = 0;
        this.modelRotationYto = 0;
        this.modelRotationSpeed = 25;
        this.modelPositionX = 0;
        this.modelLastUpdate = 0;
        this.modelPositionSpeed = 25;
        this.modelReady = false;

        // Alles voor de muur (2x zijkant - met stenen textuur)
        this.wallGeometry = undefined;
        this.wallMaterial = undefined;
        this.shadowmaterial = undefined; // Schaduw onder wall cube

        // Alles voor de textuur van de muur incl. losse stenen
        this.wallTexture = {};
        this.wallTexture.alphaMap = [];
        this.wallTexture.metalMapImg = [];
        this.wallTexture.glossMapImg = [];
        this.wallTexture.colorMapImg = [];
        this.wallTexture.normalMapImg = [];
        this.wallTexture.roughMapImg = [];
        this.wallTexture.displacementImg = [];
        this.wallTexture.loadingCount = 0;

        this.wallTexture.colorMapCtx = undefined;
        this.wallTexture.normalMapCtx = undefined;
        this.wallTexture.roughMapCtx = undefined;
        this.wallTexture.metalMapCtx = undefined;
        this.wallTexture.glossMapCtx = undefined;
        this.wallTexture.displacementMapCtx = undefined;

        this.wallTexture.colorMapTexture = undefined;
        this.wallTexture.normalMapTexture = undefined;
        this.wallTexture.roughMapTexture = undefined;
        this.wallTexture.metalMapTexture = undefined;
        this.wallTexture.glossMapTexture = undefined;
        this.wallTexture.displacementMapTexture = undefined;

        this.wallTexture.colorMapCanvas = document.createElement('canvas');
        this.wallTexture.displacementMapCanvas = document.createElement('canvas');
        this.wallTexture.roughMapCanvas = document.createElement('canvas');
        this.wallTexture.normalMapCanvas = document.createElement('canvas');
        this.wallTexture.glossMapCanvas = document.createElement('canvas');
        this.wallTexture.metalMapCanvas = document.createElement('canvas');

        this.wallTexture.grout = {};
        this.wallTexture.grout.colorMapImg = new Image;
        this.wallTexture.grout.normalMapImg = new Image;
        this.wallTexture.grout.displacementMapImg = new Image;
        this.wallTexture.grout.displacementMapImgL = new Image;

        this.topClippingPlane = undefined; // Plane die de bovenkant van de kubus afknipt
        this.topOffRendering = 1; // Hoogste positie rendering
        this.maxTopBricks = 2048; // Hoogte bovenkant van steen rij

        this.normalScaleMultiply = 0.8;

        // De config bepaalt hoe de muur er uitziet wat betreft
        // diepte van de voegen en hoeveel stenen er zichtbaar zijn
        this.config = {};

        this.config.offsetBricks = 512;
        this.config.seamX = 443;
        this.config.seamY = 1682;
        this.config.seamCorrectX = 60;
        this.config.seamCorrectY = 120;
        this.config.scale = 0.6477272727272727;
        this.config.normalScale = 0.5;
        this.config.displacementScale = 0.025;
        this.config.displacementBricks = 1.5;
        this.config.maxRotation = 0.004;
        this.config.displacementBricksCrooked = 0.5;
        this.config.randomSeed = 0;
        this.config.jointColor = '#666666';
        this.percentages = [];

        // https://github.com/justinmahar/random-seed-weighted-chooser
        this.chooser = require('random-seed-weighted-chooser').default;

        // Settings komen uit de VueJS configurator en bepalen de look & feel
        // van de gehele muur (wordt omgezet naar config)
        this.settings = {
            scene_type: 'brick', // brick, wall and facade. (export voor seamless test)
            bond: 'Halfsteens',
            depth: 6,
            ribbon_joint: 10,
            butt_joint: 10,
            joint_color: 'gray',
            shuffle: 0
        };

        this.model = new THREE.Mesh();
        this.baseCube = new THREE.Mesh();

        this.products = []; // Array van actieve producten

        let self = this;

        // Op configurator page init direct starten
        if (document.getElementsByClassName('page-type-configurator').length) {
            self.initAll();
        } else {
            //op andere pagina's wachten tot gescrold wordt.
            window.onscroll = function () {
                self.initAll();
                window.onscroll = null;
            };
        }

        document.addEventListener('mousemove', e => {
            let x = self.domInfo.containerLeft + (self.domInfo.containerWidth / 2) - e.clientX;
            let y = self.domInfo.containerTop + (self.domInfo.containerHeight / 2) - e.clientY;
            self.mouseMove(x, y);
        });

        window.addEventListener('resize', e => {
            self.updateDomInfo();
        });

        window.addEventListener('scroll', e => {
            self.updateDomInfo();
            self.hasScrolled = true;
        });

        if (window.DeviceOrientationEvent) {
            // Rotatie vam device bijhouden
            window.addEventListener('deviceorientation', function (event) {
                self.domInfo.deviceOrientation.beta = event.beta;
                self.domInfo.deviceOrientation.gamma = event.gamma;
                self.domInfo.deviceOrientation.alpha = event.alpha;

                self.mouseMove(0, 0);
            }, true);
        }

        this.updateSettings(this.settings);
        this.setWidth(2);
    }

    /**
     * Alle inits wordt 1 keer uitgevoerd bij laden pagina
     * @param drawMode
     */
    initAll(drawMode) {
        this.getMode();
        this.loadWallTextures();
        this.initWorld();
        this.initLights();
        this.createWallMaterial();
        this.initWall();
        if (this.loadMode !== 'home') this.start();
        this.updateDomInfo();

        if (drawMode !== undefined) this.setDrawMode(drawMode);
    }

    /**
     * Haal modus van viewer op
     */
    getMode() {
        if (this.domInfo.container.dataset.mode === 'product' || this.domInfo.container.dataset.mode === 'inline-product') {

            if (this.domInfo.container.dataset.option === 'home') {
                this.loadMode = 'home';
            }

            if (this.domInfo.container.dataset.option === 'error') {
                this.loadMode = 'error';
            }

            if (this.domInfo.container.dataset.productnumber !== undefined) {

                let self = this;

                window.Collection.getProductFromJSON(self.domInfo.container.dataset.productnumber).then(function (response) {
                    let product = response.product;

                    let productSize = window.Collection.getProductSizeFromName(product.size);
                    self.products[0].length = productSize.length;
                    self.products[0].width = productSize.width;
                    self.products[0].height = productSize.height;
                    self.products[0].name = product.name;
                    self.products[0].name_long = product.name_long;
                    self.products[0].averageColor = product.averageColor;
                    self.products[0].classificationname = product.classificationname;

                    try {
                        let renderData = JSON.parse(product.renderData);
                        self.products[0].renderData = renderData;
                    } catch (error) {
                        console.error(error);
                    }

                    self.adjustLightOnColor();
                });

                let product = this.initProduct(this.domInfo.container.dataset.productnumber, this.brickTextureBaseCount);
                let dataSetSize = this.domInfo.container.dataset.size;

                if (dataSetSize !== '' && dataSetSize !== undefined) {
                    let productSize = window.Collection.getProductSizeFromName(this.domInfo.container.dataset.size);
                    product.length = productSize.length;
                    product.width = productSize.width;
                    product.height = productSize.height;
                }

                this.products.push(product);
                this.adjustLightOnColor();
            }

            if (this.domInfo.container.dataset.mode === 'product') {
                this.setDrawMode('product');
            } else {
                this.setDrawMode('inline-product');
            }

            // Onderstaand alleen uitvoeren bij inline-product. In andere geval komt data uit Vue component.
            if (this.domInfo.container.dataset.mode === 'inline-product') {
                let numbers = document.getElementsByClassName('product-viewer--number');
                if (numbers[0] !== undefined) numbers[0].innerHTML = this.domInfo.container.dataset.productnumber;

                let self = this;

                window.Collection.getProductFromJSON(this.domInfo.container.dataset.productnumber).then(function (response) {
                    let product = response.product;

                    let numbers = document.getElementsByClassName('product-viewer--serie');
                    if (numbers[0] !== undefined) numbers[0].innerHTML = product.series[0];

                    let longName = document.getElementsByClassName('product-viewer--name-long');
                    if (longName[0] !== undefined) longName[0].innerHTML = product.name_long;

                    // Projecten opbouwen
                    let projectContainer = document.getElementsByClassName('rodruza-projects--popup');
                    if (projectContainer[0] !== undefined && 'projects' in product && product.projects.length > 0) {
                        let projectsTitle = document.getElementsByClassName('projects-title');
                        if (projectsTitle[0] !== undefined) {
                            projectsTitle[0].style.display = 'block';
                        }
                        // Loop over project en maak HTML aan.
                        product.projects.forEach(function (project) {
                            // window.projectOverviewPagePath wordt ingesteld in de view.php van het blok rodruza_productviewer
                            projectContainer[0].insertAdjacentHTML('beforeend', `<li class="ais-InfiniteHits-item">
                                    <a href="${project.url ?? window.projectOverviewPagePath}">
                                    <div class="rodruza-projects__info-card">
                                        <div class="info-img">
                                            <picture class="info-img--hover">
                                                ${project.image_hover.sources.map((source) => {
                                return `<source  srcset="${source.srcset}" media="${source.media}">`;
                            }).join('')}
                                                <img src="${project.image_hover.image.src}" alt="${project.image_hover.image.alt}">
                                            </picture>
                                            <picture>
                                                ${project.image.sources.map((source) => {
                                return `<source  srcset="${source.srcset}" media="${source.media}">`;
                            }).join('')}
                                                <img src="${project.image.image.src}" alt="${project.image.image.alt}">
                                            </picture>
                                        </div>
                                        <div class="info-line info-line--white">${project.name}</div>
                                        <div class="info-line info-line--white">${project.location}</div>
                                    </div>
                                    </a>
                                </li>`);
                        });
                    }

                    let productSize = window.Collection.getProductSizeFromName(product.size);
                    self.products[0].length = productSize.length;
                    self.products[0].width = productSize.width;
                    self.products[0].height = productSize.height;

                    self.products[0].name = product.name;
                    self.products[0].name_long = product.name_long;
                    self.products[0].averageColor = product.averageColor;
                    self.products[0].classificationname = product.classificationname;
                    self.products[0].classificationname = product.classificationname;
                    try {
                        let renderData = JSON.parse(product.renderData);
                        self.products[0].renderData = renderData;
                    } catch (error) {
                        self.handleError('renderData 1 error, ' + error);
                        console.error(error);
                    }

                    self.adjustLightOnColor();

                });
            }
        }

    }

    /**
     * Vertaal muisbeweging in rotatie cubus
     * @param x muis x
     * @param y muis y
     */
    mouseMove(x, y) {
        x = Math.floor(x);
        y = Math.floor(y);

        if (x === 0 && y == 0) {} else {
            this.modelRotationXto = -45 - (x / 15) + 15;
            this.modelRotationYto = 0 - (y / 60);

            if (this.drawMode === 'product') {
                this.modelRotationXto = -45 - (x / 17) - 10;
            }
        }

        if (this.domInfo.mobileMode) {
            this.modelRotationXto = -45 - this.domInfo.deviceOrientation.gamma * 2;

            if (Math.abs(this.domInfo.deviceOrientation.beta) > 45) {
                this.modelRotationYto = 0;
            } else {
                this.modelRotationYto = -this.domInfo.deviceOrientation.beta * 4;
            }
        }

        if (this.modelRotationYto < -7) this.modelRotationYto = -7;
        if (this.modelRotationYto > 7) this.modelRotationYto = 7;

        if (this.modelRotationXto < -90) this.modelRotationXto = -90;
        if (this.drawMode === 'product' && !this.domInfo.mobileMode)
            if (this.modelRotationXto < -25) this.modelRotationXto = -25;
        if (this.modelRotationXto > 0) this.modelRotationXto = 0;

        if (this.drawMode === 'product-render') {
            this.modelRotationXto = -90;
            this.modelRotationYto = 0;
        }

        if (this.settings.bond === 'Staande rollaag') {
            if (this.modelRotationXto < -17) this.modelRotationXto = -17;
            //if (this.modelRotationXto > 0) this.modelRotationXto = 0;
        }

        if (this.drawMode === 'seamless') {
            this.modelRotationLock = true;
            this.modelRotationLockX = 0;
            this.modelRotationLockY = 0;
        }

        if (this.modelRotationLock) {
            this.modelRotationXto = this.modelRotationLockX;
            this.modelRotationYto = this.modelRotationLockY;
        }

    }

    updateCorrectionSize(correctionWidth, correctionHeight) {
        if (this.products[0].renderData !== undefined && this.products[0].renderData !== null) {

            this.products[0].renderData.correctionWidth = correctionWidth;
            this.products[0].renderData.correctionHeight = correctionHeight;
        }

        this.drawBricksWhenLoaded();
    }

    updateColor(headerHueRotate, headerSaturation, headerBrightness, hueRotate, saturation, brightness) {
        if (this.products[0].renderData !== undefined && this.products[0].renderData !== null) {
            this.products[0].renderData.headerHueRotate = headerHueRotate;
            this.products[0].renderData.headerSaturation = headerSaturation;
            this.products[0].renderData.headerBrightness = headerBrightness;
            this.products[0].renderData.hueRotate = hueRotate;
            this.products[0].renderData.saturation = saturation;
            this.products[0].renderData.brightness = brightness;
        }

        this.drawBricksWhenLoaded();
    }

    /**
     * Update de settings / config van de ProductViewer
     * @param _settings
     */
    updateSettings(_settings) {
        this.settings.scene_type = _settings.scene_type;
        this.settings.bond = _settings.bond;
        this.settings.depth = _settings.depth;
        this.settings.ribbon_joint = _settings.ribbon_joint;
        this.settings.butt_joint = _settings.butt_joint;
        this.settings.joint_color = _settings.joint_color;
        this.settings.shuffle = _settings.shuffle;

        if (this.drawMode !== 'product-render') {
            this.config.seamX = Math.round(433 + (5 * (this.settings.ribbon_joint - 10)));
            this.config.seamY = Math.round(1682 + (5 * (this.settings.butt_joint - 10)));

            switch (this.settings.bond) {
                case 'Blok':
                    this.config.offsetBricks = 0;
                    break;
                default:
                    this.config.offsetBricks = 512;
                    break;
            }

            switch (this.settings.joint_color) {
                case 'white': // Wit (2000)
                    this.config.jointColor = '#d5d1d1';
                    break;
                case 'beige_yellow': // Beige geel (2030)
                    this.config.jointColor = '#c5b38e';
                    break;
                case 'brown_yellow': // Bruin geel (2050)
                    this.config.jointColor = '#cca557';
                    break;
                case 'orange_red': // Oranje rood (2140)
                    this.config.jointColor = '#9b6c48';
                    break;
                case 'pink_red': // Roze rood (2245)
                    this.config.jointColor = '#8d605b';
                    break;
                case 'light_brown': // Lichtbruin (2340)
                    this.config.jointColor = '#8c6a54';
                    break;
                case 'dark_anthracite': // Donker antraciet (2745)
                    this.config.jointColor = '#444444';
                    break;
                case 'black': // Zwart (2750)
                    this.config.jointColor = '#343434';
                    break;
                case 'broken_white': // Gebroken wit (2771)
                    this.config.jointColor = '#cac6bc';
                    break;
                case 'light_gray': // Lichtgrijs (2772)
                    this.config.jointColor = '#a8978b';
                    break;
                case 'gray': // Lichtgrijs (2772)
                    this.config.jointColor = '#8b807d';
                    break;
                case 'anthracite': // Antraciet (2774)
                    this.config.jointColor = '#524849';
                    break;
                default:
                    this.config.jointColor = '#666666';
                    break;
            }

            this.config.randomSeed = this.settings.shuffle;

            switch (this.settings.scene_type) {
                case 'brick':
                    this.config.displacementScale = this.settings.depth * 0.0035 * 0.5;
                    this.setWidth(2);
                    break;
                case 'wall':
                    this.config.displacementScale = this.settings.depth * 0.003 * 0.5;
                    this.setWidth(4);
                    break;
                case 'facade':
                    this.config.displacementScale = this.settings.depth * 0.002 * 0.5;
                    this.setWidth(10);
                    break;
                case 'export':
                    this.setDrawMode('seamless');
                    break;
            }

            if (this.wallMaterial !== undefined) {
                this.wallMaterial.normalScale.set(this.normalScaleMultiply, this.normalScaleMultiply).multiplyScalar(this.config.normalScale);
                this.wallMaterial.displacementScale = this.config.displacementScale;
                this.wallMaterial.displacementBias = -this.config.displacementScale / 2;
            }

            this.drawBricksWhenLoaded();
        } else {
            this.logoPlane.visible = false;
        }
    }

    /**
     * Bereken de scale op basis van het aantal hele stenen + voeg in de breedte van 1 kubus kan
     * @param showBricks
     */
    setWidth(showBricks) {
        if (this.products !== undefined) {
            if (this.products.length > 0) {
                let baseProduct = this.products[0];

                let tl = baseProduct.length * showBricks + this.settings.butt_joint * showBricks - this.settings.butt_joint;
                this.config.scale = 287 / tl;
                if (this.settings.bond === 'Staande rollaag') {
                    this.config.scale = 275 / tl;
                }
                if (this.drawMode === 'seamless') {
                    tl = baseProduct.length * showBricks + this.settings.butt_joint * showBricks;
                    this.config.scale = 285 / tl;
                    if (this.settings.bond === 'Staande rollaag') {
                        tl = baseProduct.width * showBricks + this.settings.butt_joint * showBricks - this.settings.butt_joint;
                        this.config.scale = 275 / tl;
                    }
                }
            } else {
                this.config.scale = 0.6477272727272727;
            }
        } else {
            this.config.scale = 0.6477272727272727;
        }
    }

    /**
     * Verdeling van stenen in de muur updaten vanuit de UI
     * @param _percentages
     */
    updatePercentages(_percentages) {
        this.percentages = _percentages;
        this.drawBricksWhenLoaded();
    }

    /**
     * Haal product op via Algolia en maak een nieuwe configuratie aan
     * @param productNumber
     */
    loadBrick(productNumber) {
        this.products = []; // Array van actieve producten

        this.mouseMove(0, 0);
        this.modelRotationLock = true;
        this.modelRotationX = 0;
        this.modelRotationXto = 0;
        this.modelRotationY = 0;
        this.modelRotationYto = 0;
        this.modelRotationLockX = 0;
        this.modelRotationLockY = 0;

        let self = this;

        window.Collection.getProductFromJSON(productNumber).then(function (response) {
            let product = response.product;
            let productSize = window.Collection.getProductSizeFromName(product.size);
            self.products[0].length = productSize.length;
            self.products[0].width = productSize.width;
            self.products[0].height = productSize.height;
            self.products[0].name = product.name;
            self.products[0].name_long = product.name_long;
            self.products[0].averageColor = product.averageColor;
            self.products[0].classificationname = product.classificationname;

            try {
                let renderData = JSON.parse(product.renderData);
                self.products[0].renderData = renderData;
            } catch (error) {
                self.handleError('renderData 2 error, ' + error);
                console.error(error);
            }

            self.adjustLightOnColor();

            setTimeout(() => {
                self.modelRotationLock = false;
            }, 500);
        });

        let product = this.initProduct(productNumber, this.brickTextureBaseCount);

        let dataSetSize = this.domInfo.container.dataset.size;
        if (dataSetSize !== '' && dataSetSize !== undefined) {
            let productSize = window.Collection.getProductSizeFromName(this.domInfo.container.dataset.size);
            product.length = productSize.length;
            product.width = productSize.width;
            product.height = productSize.height;
        }

        this.products.push(product);
        this.drawBricksWhenLoaded();
        this.adjustLightOnColor();
    }

    /**
     * Werk canvas bij met extra/nieuwe stenen.
     */
    updateBricks(bricks) {
        if (bricks.length > 0) {
            this.products = []; // Array van actieve producten

            bricks.forEach((brick) => {
                if (brick !== null && brick !== undefined) {
                    this.product = this.initProduct(brick.product_number, this.brickTextureBaseCount, brick);
                    this.products.push(this.product);
                    this.adjustLightOnColor();
                }
            });

            switch (this.settings.scene_type) {
                case 'brick':
                    this.config.displacementScale = this.settings.depth * 0.0035 * 0.5;
                    this.setWidth(2);
                    break;
                case 'wall':
                    this.config.displacementScale = this.settings.depth * 0.003 * 0.5;
                    this.setWidth(4);
                    break;
                case 'facade':
                    this.config.displacementScale = this.settings.depth * 0.002 * 0.5;
                    this.setWidth(10);
                    break;
                case 'export':
                    this.setWidth(6);
                    break;

            }

            if (this.wallMaterial !== undefined) {
                this.wallMaterial.normalScale.set(this.normalScaleMultiply, this.normalScaleMultiply).multiplyScalar(this.config.normalScale);
                this.wallMaterial.displacementScale = this.config.displacementScale;
                this.wallMaterial.displacementBias = -this.config.displacementScale / 2;
            }

            this.drawBricksWhenLoaded();
            this.adjustLightOnColor();
        }
    }

    /**
     * set de drawmode
     * product-render = direct van voren tbv van product renderen van afbeelding
     * @param drawMode
     */
    setDrawMode(drawMode) {
        this.drawMode = drawMode;

        if (this.drawMode === 'product-render') {
            this.shadowmaterial.opacity = 0;
            this.logoPlane.visible = false;

            this.modelRotationX = 0;
            this.modelRotationXto = 0;
            this.modelRotationY = 0;
            this.modelRotationYto = 0;

            this.config.seamX = 443;
            this.config.seamY = 1682;
            this.config.seamCorrectX = 60;
            this.config.seamCorrectY = 120;

            this.settings = {
                scene_type: 'brick', // brick, wall and facade. (export voor seamless test)
                bond: 'Halfsteens',
                depth: 6,
                ribbon_joint: 10,
                butt_joint: 10,
                joint_color: 'gray',
                shuffle: 0
            };

            this.updateSettings(this.settings);
            this.setWidth(2);

            this.config.normalScale = 0.5;
            this.config.displacementScale = 0.025;
            this.config.displacementBricks = 1.5;
            this.config.maxRotation = 0.004;
            this.config.displacementBricksCrooked = 0.5;

            this.doDrawBricks++;

            setTimeout(() => {
                this.drawMode = 'normal';
                this.modelRotationLock = false;
                this.updateSettings(this.settings);
            }, 3000);
        }

        if (this.drawMode === 'seamless') {
            this.shadowmaterial.opacity = 0;
            this.logoPlane.visible = false;

            this.modelRotationX = 0;
            this.modelRotationXto = 0;
            this.modelRotationY = 0;
            this.modelRotationYto = 0;

            this.modelRotationLock = true;
            this.modelRotationLockX = 0;
            this.modelRotationLockY = 0;

            this.setWidth(6);

            if (this.settings.bond === 'Staande rollaag') {
                this.settings.ribbon_joint = 8;
                if (this.settings.butt_joint < 2) {
                    this.settings.butt_joint_org = this.settings.butt_joint;
                    this.settings.butt_joint = 2;
                }
            }
            //this.config.displacementScale = this.settings.depth * 0.002 * 0.5;

            this.config.normalScale = 0.5;
            this.doDrawBricks++;
        }

        if (this.drawMode === 'product' || this.drawMode === 'inline-product') {
            this.config.seamX = 443;
            this.config.seamY = 1682;
            this.config.seamCorrectX = 60;
            this.config.seamCorrectY = 120;

            this.config.normalScale = 0.5;
            this.config.displacementScale = 0.025;
            this.config.displacementBricks = 1.5;
            this.config.maxRotation = 0.004;
            this.config.displacementBricksCrooked = 0.5;
            this.config.randomSeed = 0;

            this.modelRotationX = 0;
            this.modelRotationXto = 0;
            this.modelRotationY = 0;
            this.modelRotationYto = 0;
            this.modelPositionX = 0;

            let _settings = {
                scene_type: 'brick', // brick, wall and facade. (export voor seamless test)
                bond: 'Halfsteens',
                depth: 6,
                ribbon_joint: 10,
                butt_joint: 10,
                joint_color: 'gray',
                shuffle: 0
            };

            this.updateSettings(_settings);
            this.setWidth(2);
            this.drawBricksWhenLoaded();
        }
    }

    /**
     * Debug info aan / uit zetten
     */
    toggleDebug() {
        this.debugMode = !this.debugMode;
        this.doDrawBricks++;
    }

    waitForFullDraw() {
        const poll = resolve => {
            if (this.doDrawBricks <= 0 && this.modelReady) resolve();
            else setTimeout(() => poll(resolve), 100);
        };

        return new Promise(poll);
    }

    hashCode(str) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = ~~(((hash << 5) - hash) + str.charCodeAt(i));
        }
        return hash;
    }

    renderSeamlessImage(callback) {
        let self = this;

        this.showHideLoading(true);

        callback('start');

        this.setDrawMode('seamless');
        this.doDrawBricks += 2;

        // Maak hash op basis van settings en producten
        // Zodat we weten of we de zip al hebben

        let hashIn = JSON.stringify(this.settings)
        hashIn += JSON.stringify(this.config);
        hashIn += this.percentages;

        this.products.forEach(product => {
            hashIn += product.product_number;
        });

        let hash = this.hashCode(hashIn);



        // Probeer zip te downloaden

        fetch(this.exportsPath + '/' + hash + 'test.zip', {
            method: 'GET'
        })
            .then(res => {
                if (res.status === 200) {
                    callback('step-3');
                    return res.blob();
                } else {
                    // Zip bestaat nog niet, maak nieuwe
                    this.waitForFullDraw(() => flag === true)
                        .then(() => {
                            callback('drawn');
                            this.zipAndSaveTextures(callback, hash);
                        });
                }
            }).then(function (blob) {
            // zip bestaat al, downloaden

            let elm = document.createElement('a');  // CREATE A LINK ELEMENT IN DOM
            elm.href = URL.createObjectURL(blob);  // SET LINK ELEMENTS CONTENTS
            elm.setAttribute('download', 'rodruza-textures-' + hash + '.zip'); // SET ELEMENT CREATED 'ATTRIBUTE' TO DOWNLOAD, FILENAME PARAM AUTOMATICALLY
            elm.click()

            self.setDrawMode('normal');
            self.showHideLoading(false);
            self.modelRotationLock = false;
            self.setWidth(2);
            self.doDrawBricks++;

            callback('ready');
        }).catch(err => {

            callback('error');
        });
    }

    showHideLoading(doShow) {
        let configuratorElements = document.querySelectorAll('.configurator__working');

        for (let i = 0; i < configuratorElements.length; i++) {
            if (doShow) {
                configuratorElements[i].classList.add('active');
                this.isWorking = true;
            } else {
                this.isWorking = false;
                setTimeout(() => {
                    configuratorElements[i].classList.remove('active');
                }, 350);
            }
        }
    }

    renderSeamlessImageFile(productCanvas, textureCanvas, exportHeight, exportWidth = 0, offsetLeft = 0, offsetWidth = 0) {

        let productCtx = productCanvas.getContext('2d');

        productCtx.fillStyle = '#FFFF';
        productCtx.fillRect(0, 0, productCanvas.width, productCanvas.height);
        productCtx.drawImage(
            textureCanvas,
            0, 0, 2048, 2048,
            offsetLeft, -exportHeight, 2048 + offsetWidth, 2048
        );

        return productCanvas.toDataURL().split(';base64,')[1];
    }

    zipAndSaveTextures(callback, hash) {
        let realToPixelRationY = 7.2; // Omrekenen van mm naar px
        let scale = this.config.scale;
        let self = this;
        let baseProduct = this.products[0]; // Basis product tbv breed en hoog, een muur kent altijd maar 1 formaat.

        let offsetLeft = 0;
        let offsetWidth = 0;

        let exportWidth = 0;
        let exportHeight = this.maxTopBricks + (baseProduct.height * realToPixelRationY * scale);
        if (this.settings.bond === 'Staande rollaag') {
            exportHeight = 0;

        }

        let productCanvas = document.createElement('canvas');
        productCanvas.width = 2048 - exportWidth;
        productCanvas.height = 2048 - exportHeight;
        if (this.settings.bond === 'Staande rollaag') {
            let offl = (15 - this.settings.butt_joint) * 20;
            if (this.settings.butt_joint === 0) offl = (15 - this.settings.butt_joint) * 23;
            offsetLeft = -baseProduct.height - offl; // Offset naar het midden vanaf links
            offsetWidth = -offsetLeft + (baseProduct.height * 1.1) + 28; // Offset naar het midden vanaf rechts
            productCanvas.height = (baseProduct.width * realToPixelRationY) + (this.settings.ribbon_joint * realToPixelRationY / 2) - 20; // Hoogte -offset (20)

            if (baseProduct.length === 210) {
                if (this.settings.butt_joint === 2) offsetLeft = -baseProduct.height - offl - 15; // Offset naar het midden vanaf links
                if (this.settings.butt_joint === 4) offsetLeft = -baseProduct.height - offl - 7; // Offset naar het midden vanaf links
            }

            if (baseProduct.length > 210) {
                offl = (15 - this.settings.butt_joint) * 12;
                if (this.settings.butt_joint === 0) offl = (15 - this.settings.butt_joint) * 14;
                offsetLeft = -baseProduct.height - offl; // Offset naar het midden vanaf links
                offsetWidth = -offsetLeft + (baseProduct.height * 1);
                productCanvas.height = (baseProduct.width * realToPixelRationY) + (this.settings.ribbon_joint * realToPixelRationY / 2) + 30; // Hoogte -offset (10)
            }
            if (baseProduct.length > 240) {
                offl = (15 - this.settings.butt_joint) * 39;
                offsetLeft = -baseProduct.height - offl - 30; // Offset naar het midden vanaf links
                if (this.settings.butt_joint === 15) offsetLeft = -baseProduct.height - offl - 60; // Offset naar het midden vanaf links
                offsetWidth = -offsetLeft + (baseProduct.height * 1.1) + 60; // Offset naar het midden vanaf rechts
                productCanvas.height = (baseProduct.width * realToPixelRationY) + (this.settings.ribbon_joint * realToPixelRationY / 2) + 100; // Hoogte -offset (20)
            }
        }

        let renderCanvas = document.createElement('canvas');
        renderCanvas.width = 2048;
        renderCanvas.height = 2048;

        let aspect = this.domInfo.canvas.width / this.domInfo.canvas.height;

        let ss = 0.605;

        let sw = this.domInfo.canvas.width * ss / aspect;
        let sh = this.domInfo.canvas.height * ss;
        let sx = this.domInfo.canvas.width / 2 - sw / 1.98;
        let sy = this.domInfo.canvas.height / 2 - sh / 2; //sdy*ss;

        let renderCtx = renderCanvas.getContext('2d');
        renderCtx.fillStyle = '#FFFF';
        renderCtx.fillRect(0, 0, renderCanvas.width, renderCanvas.height);
        renderCtx.drawImage(
            this.domInfo.canvas,
            sx, sy, sw, sh,
            0, 0, renderCanvas.width, renderCanvas.height
        );

        let infoText = 'Rodruza texture export.\n\n';

        infoText += 'Producten:\n';

        for (let i = 0; i < this.products.length; i++) {
            infoText += 'Steen: ' + this.products[i].product_number + ' | ' + this.products[i].name + ' | ' + this.products[i].finish + ' | ' + this.percentages[i] + '%\n';
        }

        infoText += '\n\nInformatie:\n';
        infoText += 'Verband: ' + this.settings.bond + ' \n';
        infoText += 'Verdiept: ' + this.settings.depth + 'mm \n';
        if (this.settings.bond !== 'Staande rollaag') infoText += 'Lintvoeg: ' + this.settings.ribbon_joint + 'mm \n';
        if (this.settings.butt_joint_org !== undefined) {
            infoText += 'Stootvoeg: ' + this.settings.butt_joint_org + 'mm \n';
        } else {
            infoText += 'Stootvoeg: ' + this.settings.butt_joint + 'mm \n';
        }
        infoText += 'Voegkleur: ' + this.settings.joint_color + ' \n';
        infoText += 'Formaat: ' + this.products[0].length + 'mm x ' + this.products[0].height + 'mm x ' + this.products[0].width + 'mm \n';

        let zip = new JSZip();
        zip.file('read-me_rodruza.txt', infoText);

        if (this.settings.bond !== 'Staande rollaag') {
            zip.file('render.png', this.renderSeamlessImageFile(productCanvas, renderCanvas, this.maxTopBricks + (baseProduct.height * realToPixelRationY * scale) / 2, exportWidth, offsetLeft, offsetWidth), {
                base64: true
            });
        }

        let img = zip.folder('textures');
        img.file('color.png', this.renderSeamlessImageFile(productCanvas, this.wallTexture.colorMapCanvas, exportHeight, exportWidth, offsetLeft, offsetWidth), {
            base64: true
        });

        let cImg = document.createElement('img');
        cImg.src = productCanvas.toDataURL();

        img.file('normal.png', this.renderSeamlessImageFile(productCanvas, this.wallTexture.normalMapCanvas, exportHeight, exportWidth, offsetLeft, offsetWidth), {
            base64: true
        });
        img.file('roughness.png', this.renderSeamlessImageFile(productCanvas, this.wallTexture.roughMapCanvas, exportHeight, exportWidth, offsetLeft, offsetWidth), {
            base64: true
        });
        img.file('metalness.png', this.renderSeamlessImageFile(productCanvas, this.wallTexture.metalMapCanvas, exportHeight, exportWidth, offsetLeft, offsetWidth), {
            base64: true
        });
        img.file('gloss.png', this.renderSeamlessImageFile(productCanvas, this.wallTexture.glossMapCanvas, exportHeight, exportWidth, offsetLeft, offsetWidth), {
            base64: true
        });
        img.file('displacement.png', this.renderSeamlessImageFile(productCanvas, this.wallTexture.displacementMapCanvas, exportHeight, exportWidth, offsetLeft, offsetWidth), {
            base64: true
        });

        callback('step-2');
        zip.generateAsync({
            type: 'blob'
        })
            .then(function (content) {
                callback('step-3');
                saveAs(content, 'rodruza-textures.zip');

                self.saveToServer(content, hash, callback);

                callback('ready');
            });

        this.setDrawMode('normal');
        this.showHideLoading(false);
        this.modelRotationLock = false;
        this.setWidth(2);
        this.doDrawBricks++;
    }

    saveToServer(content, hash, callback) {
        fetch('/zl/save-export?hash='+hash, {
            method: 'POST',
            body: content,
        })
            .then(response => {
                if (response.ok) {

                } else {
                    console.error('Error: ', response);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }


    moveLock(lock) {
        switch (lock) {
            case 'lock':
                this.modelRotationLock = true;
                this.modelRotationLockX = -25;
                this.modelRotationLockY = 0;
                break;
            case 'front':
                this.modelRotationLock = true;
                this.modelRotationLockX = 0;
                this.modelRotationLockY = 0;
                break;
            default:
                this.modelRotationLock = false;
        }

        this.mouseMove(0, 0);
        this.modelRotationLockMode = lock;

    }

    /**
     * Haal de gemiddelde kleur op van een aantal gedeeltes van een canvas
     * @param ctx is the context of the canvas
     * @param regions is an array of objects with x, y, width, and height properties
     * @returns {string} kleur in hexadecimaal
     */
    getAverageColor(ctx, regions) {
        let colorSum = [0, 0, 0];
        let pixelCount = 0;

        for (let i = 0; i < regions.length; i++) {
            let region = regions[i];
            let imageData = ctx.getImageData(region.x, region.y, region.width, region.height);
            let data = imageData.data;

            for (let j = 0; j < data.length; j += 4) {
                colorSum[0] += data[j];
                colorSum[1] += data[j + 1];
                colorSum[2] += data[j + 2];
                pixelCount++;
            }
        }

        // Calculate the average color
        let avgColor = [
            Math.round(colorSum[0] / pixelCount),
            Math.round(colorSum[1] / pixelCount),
            Math.round(colorSum[2] / pixelCount)
        ];

        // Convert the average color to a hex string
        let hexColor = '#' + this.rgbToHex(avgColor[0]) + this.rgbToHex(avgColor[1]) + this.rgbToHex(avgColor[2]);

        return hexColor;
    }

    /**
     * Converteer rgba naar hexadicimaal
     * @param rgb
     * @returns {string}
     */
    rgbToHex(rgb) {
        let hex = Number(rgb).toString(16);
        if (hex.length < 2) {
            hex = '0' + hex;
        }
        return hex;
    }

    /**
     * Render een product Image
     */
    renderProductImage() {
        this.setDrawMode('product-render');

        this.doDrawBricks++;

        setTimeout(() => {

            debugger;
            let productCanvas = document.createElement('canvas');
            productCanvas.width = 2048; // HIER42
            productCanvas.height = 2048;

            let aspect = this.domInfo.canvas.width / this.domInfo.canvas.height;

            let ss = 0.605;

            let sw = this.domInfo.canvas.width * ss / aspect;
            let sh = this.domInfo.canvas.height * ss;
            let sx = this.domInfo.canvas.width / 2 - sw / 1.98;
            let sy = this.domInfo.canvas.height / 2 - sh / 2; //sdy*ss;

            let productCtx = productCanvas.getContext('2d');
            productCtx.fillStyle = '#FFFF';
            productCtx.fillRect(0, 0, productCanvas.width, productCanvas.height);
            productCtx.drawImage(
                this.domInfo.canvas,
                sx, sy, sw, sh,
                0, 0, productCanvas.width, productCanvas.height
            );

            let pImg = document.createElement('img');
            pImg.src = productCanvas.toDataURL();
            pImg.style.position = 'absolute';
            pImg.style.top = '200px';
            pImg.style.right = '10px';
            pImg.style.width = '256px';
            pImg.style.height = '256px';
            pImg.style.outline = '1px solid red';
            document.body.appendChild(pImg);

            let regions = [{
                x: 100,
                y: 125,
                width: 20,
                height: 20
            },
                {
                    x: 100,
                    y: 925,
                    width: 20,
                    height: 20
                },
                {
                    x: 825,
                    y: 125,
                    width: 20,
                    height: 20
                },
                {
                    x: 825,
                    y: 925,
                    width: 20,
                    height: 20
                }
            ];

            let averageColor = this.getAverageColor(productCtx, regions);

            let postData = {
                image: productCanvas.toDataURL('image/jpeg', 0.9),
                averageColor: averageColor,
                objectID: this.products[0].objectID,
                renderData: '{"headerHueRotate": ' + this.products[0].renderData.headerHueRotate +
                    ', "headerSaturation": ' + this.products[0].renderData.headerSaturation +
                    ', "headerBrightness": ' + this.products[0].renderData.headerBrightness +
                    ', "hueRotate": ' + this.products[0].renderData.hueRotate +
                    ', "saturation": ' + this.products[0].renderData.saturation +
                    ', "brightness": ' + this.products[0].renderData.brightness +
                    ', "correctionHeight": ' + this.products[0].renderData.correctionHeight +
                    ', "correctionWidth": ' + this.products[0].renderData.correctionWidth + '}',
                folder: this.products[0].product_number
            };
            fetch('/zl/save-product-render', {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
            }).then((response) => {
                if (response.ok) {
                    // clear sessionStorage 'products'
                    sessionStorage.removeItem('products');
                    return response.text();
                }
                throw new Error(response.text());
            }).catch((error) => {
                this.handleError('JSON error, ' + error);
                console.error('Error:', error);
            });
        }, 1000);
    }

    handleError(error) {
        console.error('Error:', error);
        //alert('Er is een fout opgetreden, probeer het later opnieuw: ' + error);

        if (this.loadMode !== 'home') {
            const errorDialog = document.getElementById('renderError');
            if (errorDialog) {
                // Dialog element exists, change its content
                errorDialog.innerHTML = 'Er is een fout opgetreden, probeer het later opnieuw: <br><br><strong>' + error + '</strong>';
            } else {
                // Dialog element does not exist, add it to the DOM
                const dialog = document.createElement('dialog');
                dialog.id = 'renderError';
                dialog.innerHTML = 'Er is een fout opgetreden, probeer het later opnieuw: <br><br><strong>' + error + '</strong>';
                // add class "configurator__disclaimer-dialog"
                dialog.classList.add('configurator__disclaimer-dialog');
                document.body.appendChild(dialog);

                dialog.showModal();
            }
        }

        // close dialog after x seconds

        setTimeout(() => {
            const dialog = document.getElementById('renderError');
            if (dialog) {
                dialog.close();
            }
        }, 5000);

    }

    /**
     * Bij window scale wat waardes van de dom ophalen en opslaan
     */
    updateDomInfo() {
        this.domInfo.containerWidth = this.domInfo.container.offsetWidth;
        this.domInfo.containerHeight = this.domInfo.container.offsetHeight;
        this.domInfo.containerLeft = this.domInfo.container.getBoundingClientRect().left;
        this.domInfo.containerTop = this.domInfo.container.getBoundingClientRect().top;

        if ($(window).width() < 990) {
            this.domInfo.mobileMode = true;
        } else {
            this.domInfo.mobileMode = false;
        }

        let pageTop = $(window).scrollTop();
        let elementTop = this.domInfo.container.offsetTop;
        if (pageTop - 300 < -elementTop) {
            this.inView = false;
        } else {
            this.inView = true;
            this.start();
        }
        if (this.loadMode !== 'home') this.inView = true;

        if (this.renderer !== undefined) {
            this.camera.aspect = this.domInfo.containerWidth / this.domInfo.containerHeight;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(this.domInfo.containerWidth * 2, this.domInfo.containerHeight * 2, false);
        }
    }

    setLoadingStatus(status) {
        this.loadingStatus = status;
    }

    /**
     * Threejs webgl init
     */
    initWorld() {
        this.setLoadingStatus('start');
        let self = this;

        this.manager.onStart = function (url, itemsLoaded, itemsTotal) {};
        this.manager.onLoad = function () {
            self.loadingStatus = 'manager-ready';

            if (self.wallTexture.loadingCount < 1) {
                setTimeout(() => {
                    if (this.products !== undefined) {
                        self.modelReady = true;
                        self.doDrawBricks = 1;
                    }
                }, 500);
                setTimeout(() => {
                    self.doDrawBricks = 1;
                }, 750);
                setTimeout(() => {
                    self.doDrawBricks = 1;
                }, 1500);
            }
        };
        this.manager.onProgress = function (url, itemsLoaded, itemsTotal) {};
        this.manager.onError = function (url) {
            console.error(self.loadingStatus + ' There was an error loading ' + url);

            if (url.indexOf('configurator-shadow2c') === -1) {
                self.handleError('picture error, ' + self.loadingStatus + ' There was an error loading ' + url);
                self.setLoadingStatus('error');
            }
        };

        this.updateDomInfo();

        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(
            60,
            this.domInfo.containerWidth / this.domInfo.containerHeight,
            0.1,
            1000
        );
        this.camera.position.set(0, 0, 2);

        this.renderer = new THREE.WebGLRenderer({
            canvas: this.domInfo.canvas,
            antialias: true,
            alpha: true,
            preserveDrawingBuffer: true
        });

        //this.renderer.setPixelRatio( window.devicePixelRatio );
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFShadowMap;
        this.renderer.physicallyCorrectLights = true;
        this.renderer.localClippingEnabled = true;
        this.renderer.setSize(this.domInfo.containerWidth * 2, this.domInfo.containerHeight * 2, false);

        this.renderer.gammaOutput = true;
        this.renderer.gammaFactor = 4.2;

        this.createBaseCube();

        this.scene.add(this.modelGroup);

        // Logo toevoegen
        const logoTexture = this.loader.load('/application/themes/z_theme/assets/images/configurator-logo.webp');
        logoTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();

        const logoGeometry = new THREE.PlaneGeometry(0.3 * 0.6, 0.1 * 0.6);
        const logoMaterial = new THREE.MeshBasicMaterial({
            map: logoTexture,
            opacity: 1,
            transparent: true,
            side: THREE.FrontSide
        });
        this.logoPlane = new THREE.Mesh(logoGeometry, logoMaterial);
        this.logoPlane.position.y = -0.4;
        this.logoPlane.position.x = -0.3;
        this.logoPlane.position.z = 0.52;
        this.logoPlane.castShadow = true;
        this.modelGroup.add(this.logoPlane);

        // Schadow onder de muur toevoegen = is plane met afbeelding
        let shadowtexture = this.loader.load('/application/themes/z_theme/assets/images/configurator-shadow2c.webp');
        shadowtexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();

        const shadowgeometry = new THREE.PlaneGeometry(3, 3);
        this.shadowmaterial = new THREE.MeshBasicMaterial({
            map: shadowtexture,
            opacity: 0,
            transparent: true,
            side: THREE.FrontSide
        });
        let shadowplane = new THREE.Mesh(shadowgeometry, this.shadowmaterial);
        shadowplane.rotation.x = -1.57079633;
        shadowplane.position.y = -0.5;
        shadowplane.position.x = 0.24;
        shadowplane.position.z = 0.1;
        this.modelGroup.add(shadowplane);
    }

    /**
     * start animatie en dus configurator
     */
    start() {
        this.animate(0);
    }

    /**
     * Animate 1 frame
     */
    animate(timestamp) {

        if (this.doDrawBricks > 0) {
            this.fillBrickArray();
            this.drawBricks();
            this.modelRotationXto += 0.1;
        }

        if (timestamp - this.previousTime > this.frameLengthMS) {
            // Limiteer het aantal frames per seconde
            this.previousTime = timestamp;

            this.modelRotationX = this.modelRotationX + (this.modelRotationXto - this.modelRotationX) / this.modelRotationSpeed;
            this.modelRotationY = this.modelRotationY + (this.modelRotationYto - this.modelRotationY) / this.modelRotationSpeed;

            if (this.drawMode === 'seamless') {
                this.modelRotationX = 0;
                this.modelRotationY = 0;
            }

            if (this.drawMode === 'product') this.modelPositionX = -0.1 + (10 - this.modelRotationX / 3) / 100;
            //this.modelPositionX = 0.2 + (-10 + this.modelRotationX ) / 300;

            if (this.modelGroup !== undefined) {
                this.modelGroup.rotation.y = THREE.MathUtils.degToRad(this.modelRotationX);
                this.modelGroup.rotation.x = THREE.MathUtils.degToRad(this.modelRotationY);
                this.modelGroup.position.x = this.modelPositionX;
            }

            // Crop plane meebewegen met cube
            let q = this.modelGroup.quaternion.clone();
            this.topClippingPlane.normal.set(0, -1.57079633, 0).applyQuaternion(q);
            this.topClippingPlane.constant = this.topOffRendering;

            if (this.doDrawBricks > 0 || this.modelLastUpdate !== Math.round((this.modelRotationX + this.modelRotationY) * 100) / 100) {
                if (this.inView) {
                    this.renderer.render(this.scene, this.camera);
                    this.modelLastUpdate = Math.round((this.modelRotationX + this.modelRotationY) * 100) / 100;
                }
            }
        }

        requestAnimationFrame(timestamp => this.animate(timestamp));
    }

    /**
     * Wireframe kubus als placeholder / loading
     */
    createBaseCube() {
        if (this.drawMode !== 'product') {

            let cubeGeometry = new THREE.BoxGeometry(1, 1, 1, 1, 1, 1);
            let geo = new THREE.EdgesGeometry(cubeGeometry); // EdgesGeometry or WireframeGeometry
            let mat = new THREE.LineBasicMaterial({
                color: 0x000000
            });
            this.baseCube = new THREE.LineSegments(geo, mat);
            this.modelGroup.add(this.baseCube);
        }

        const lineMaterial = new THREE.LineDashedMaterial({
            color: 0x000000,
            linewidth: 1,
            scale: 2,
            dashSize: 0.008,
            gapSize: 0.015,
        });

        const pointsLeft = [];
        pointsLeft.push(new THREE.Vector3(-0.505, -0.53, 0.5));
        pointsLeft.push(new THREE.Vector3(-0.505, 0.5, 0.5));

        const geometryLeft = new THREE.BufferGeometry().setFromPoints(pointsLeft);

        const lineLeft = new THREE.Line(geometryLeft, lineMaterial);
        lineLeft.computeLineDistances();
        lineLeft.scale.set(1, 1, 1);
        this.modelGroup.add(lineLeft);

        const pointsRight = [];
        pointsRight.push(new THREE.Vector3(0.5, -0.53, -0.505));
        pointsRight.push(new THREE.Vector3(0.5, 0.5, -0.505));

        const geometryRight = new THREE.BufferGeometry().setFromPoints(pointsRight);

        const lineRight = new THREE.Line(geometryRight, lineMaterial);
        lineRight.computeLineDistances();
        lineRight.scale.set(1, 1, 1);
        this.modelGroup.add(lineRight);
    }

    /**
     * Alle lampen initialiseren en op de juiste plek zetten
     */
    initLights() {
        this.lights.light = new THREE.DirectionalLight(0xe6dbdb, 5.79); //0xe6dbdb
        this.lights.light.position.set(-1.09, 2, 2.66);
        this.lights.light.castShadow = true;
        this.lights.light.shadow.bias = -0.0029;
        this.lights.light.shadow.mapSize.width = 2048;
        this.lights.light.shadow.mapSize.height = 2048;
        this.lights.light.shadow.camera.left = -1.749;
        this.lights.light.shadow.camera.right = 1.117;
        this.lights.light.shadow.camera.top = -1.308;
        this.lights.light.shadow.camera.bottom = 1.117;
        this.lights.light.shadow.camera.near = 0.455;
        this.lights.light.shadow.camera.far = 5.022;
        this.lights.light.shadow.radius = 3.220;
        this.lights.light.shadow.darkness = 0.2;
        this.lights.light.shadow.blurSamples = 10;

        this.scene.add(this.lights.light);

        this.lights.lightb = new THREE.SpotLight(0x003591, 30.35); //2e7aff
        this.lights.lightb.position.set(2.22, 2.88, -0.21);
        this.lights.lightb.lookAt(0, 0, 0);
        this.lights.lightb.angle = Math.PI / 5;
        this.lights.lightb.penumbra = 0.3;

        this.lights.lightb.castShadow = false;
        this.lights.lightb.shadow.mapSize.width = 1024 * 1;
        this.lights.lightb.shadow.mapSize.height = 1024 * 1;
        this.lights.lightb.shadow.bias = -0.001;

        this.lights.lightb.shadow.camera.left = -1.749;
        this.lights.lightb.shadow.camera.right = 1.117;
        this.lights.lightb.shadow.camera.top = -1.308;
        this.lights.lightb.shadow.camera.bottom = 1.117;
        this.lights.lightb.shadow.camera.near = 0.455;
        this.lights.lightb.shadow.camera.far = 5.022;
        this.lights.lightb.shadow.radius = 3.220;
        this.lights.lightb.shadow.darkness = 0.2;
        this.lights.lightb.shadow.blurSamples = 10;

        this.scene.add(this.lights.lightb);

        this.lights.lightc = new THREE.SpotLight(0xfffee9, 8.13); //f9f59e fffee9
        this.lights.lightc.position.set(-10, 2.66, -0.87);
        this.lights.lightc.angle = 0.07;
        this.lights.lightc.penumbra = 0.34;

        this.lights.lightc.castShadow = false;
        this.scene.add(this.lights.lightc);

        this.lights.lighta = new THREE.AmbientLight(0xffffff, 0.15);

        this.scene.add(this.lights.lighta);

        this.adjustLightOnColor();
    }

    /**
     * Voor de muur(en) maken we een plane die we om een hoek vouwen
     */
    initWall() {
        this.wallGeometry = new THREE.PlaneGeometry(2, 1, 1200, 300);

        this.wallGeometry.setAttribute('orgPos', new THREE.BufferAttribute(new Float32Array(this.wallGeometry.attributes.position.count * 3), 1));

        // Vouw de plane in een kubus (2x muur)
        for (let i = 0; i < this.wallGeometry.attributes.position.count; i++) {
            let x = this.wallGeometry.attributes.position.getX(i);
            let y = this.wallGeometry.attributes.position.getY(i);
            let z = this.wallGeometry.attributes.position.getZ(i);
            if (x > 0) {
                z = z - x;
                x = 0;
            }
            this.wallGeometry.attributes.orgPos.array[0 + i * 3] = x;
            this.wallGeometry.attributes.orgPos.array[1 + i * 3] = y;
            this.wallGeometry.attributes.orgPos.array[2 + i * 3] = z;
        }

        this.wallGeometry.computeVertexNormals();
        this.wallGeometry.attributes.position.needsUpdate = true;

        this.model = new THREE.Mesh(this.wallGeometry, this.wallMaterial);
        this.model.visible = false;
        this.model.castShadow = true;
        this.model.receiveShadow = true;
        this.model.position.x = 0.5;
        this.model.position.z = 0.5;
        this.model.material.shading = THREE.SmoothShading;

        this.wallGeometry.computeVertexNormals();
        this.wallGeometry.verticesNeedUpdate = true;

        this.modelGroup.add(this.model);

        this.updateMesh();
    }

    /**
     * Vouw de Mesh onder een hoek zodat het een kubus suggereert
     */
    updateMesh() {
        for (let i = 0; i < this.wallGeometry.attributes.position.count; i++) {
            let x = this.wallGeometry.attributes.position.getX(i);
            let y = this.wallGeometry.attributes.position.getY(i);
            let z = this.wallGeometry.attributes.position.getZ(i);

            let ox = this.wallGeometry.attributes.orgPos.array[0 + i * 3];
            let oy = this.wallGeometry.attributes.orgPos.array[1 + i * 3];
            let oz = this.wallGeometry.attributes.orgPos.array[2 + i * 3];

            this.wallGeometry.attributes.position.setX(i, ox);
            this.wallGeometry.attributes.position.setY(i, oy);
            this.wallGeometry.attributes.position.setZ(i, oz);
        }

        this.wallGeometry.computeVertexNormals();
        this.wallGeometry.verticesNeedUpdate = true;
    }

    /**
     * Maan een texture
     * @param ctx
     * @returns {CanvasTexture}
     */
    createTexture(ctx) {
        ctx.canvas.width = 2048 * 2;
        ctx.canvas.height = 2048;
        ctx.fillStyle = '#CCC';
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        return new THREE.CanvasTexture(ctx.canvas);
    }

    /**
     * Maak de textuur en maps van de muur
     */
    createWallMaterial() {
        this.wallTexture.colorMapCtx = this.wallTexture.colorMapCanvas.getContext('2d');
        this.wallTexture.colorMapTexture = this.createTexture(this.wallTexture.colorMapCtx);
        this.wallTexture.colorMapTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
        this.wallTexture.colorMapTexture.wrapS = THREE.RepeatWrapping;
        this.wallTexture.colorMapTexture.wrapT = THREE.RepeatWrapping;

        this.wallTexture.displacementMapCtx = this.wallTexture.displacementMapCanvas.getContext('2d');
        this.wallTexture.displacementMapTexture = this.createTexture(this.wallTexture.displacementMapCtx);
        this.wallTexture.displacementMapTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
        this.wallTexture.displacementMapTexture.wrapS = THREE.RepeatWrapping;
        this.wallTexture.displacementMapTexture.wrapT = THREE.RepeatWrapping;

        this.wallTexture.roughMapCtx = this.wallTexture.roughMapCanvas.getContext('2d');
        this.wallTexture.roughMapTexture = this.createTexture(this.wallTexture.roughMapCtx);
        this.wallTexture.roughMapTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
        this.wallTexture.roughMapTexture.wrapS = THREE.RepeatWrapping;
        this.wallTexture.roughMapTexture.wrapT = THREE.RepeatWrapping;

        this.wallTexture.normalMapCtx = this.wallTexture.normalMapCanvas.getContext('2d');
        this.wallTexture.normalMapTexture = this.createTexture(this.wallTexture.normalMapCtx);
        this.wallTexture.normalMapTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
        this.wallTexture.normalMapTexture.wrapS = THREE.RepeatWrapping;
        this.wallTexture.normalMapTexture.wrapT = THREE.RepeatWrapping;

        this.wallTexture.glossMapCtx = this.wallTexture.glossMapCanvas.getContext('2d');
        this.wallTexture.glossMapTexture = this.createTexture(this.wallTexture.glossMapCtx);
        this.wallTexture.glossMapTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
        this.wallTexture.glossMapTexture.wrapS = THREE.RepeatWrapping;
        this.wallTexture.glossMapTexture.wrapT = THREE.RepeatWrapping;

        this.wallTexture.metalMapCtx = this.wallTexture.metalMapCanvas.getContext('2d');
        this.wallTexture.metalMapTexture = this.createTexture(this.wallTexture.metalMapCtx);
        this.wallTexture.metalMapTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
        this.wallTexture.metalMapTexture.wrapS = THREE.RepeatWrapping;
        this.wallTexture.metalMapTexture.wrapT = THREE.RepeatWrapping;

        this.topClippingPlane = new THREE.Plane(new THREE.Vector3(0, 0, 0), 0);

        this.wallMaterial = new THREE.MeshPhysicalMaterial({
            clippingPlanes: [this.topClippingPlane],
            clipShadows: true,
            clipIntersection: false,
            side: THREE.FrontSide,
            transparent: true,
        });
        this.wallMaterial.map = this.wallTexture.colorMapTexture;
        this.wallMaterial.normalMap = this.wallTexture.normalMapTexture;
        this.wallMaterial.normalScale.set(this.normalScaleMultiply, this.normalScaleMultiply).multiplyScalar(this.config.normalScale);
        this.wallMaterial.roughnessmap = this.wallTexture.roughMapTexture;
        this.wallMaterial.roughness = 0.75;
        this.wallMaterial.metalness = 0.35;
        this.wallMaterial.metalnessmap = this.wallTexture.metalMapTexture;
        this.wallMaterial.glossinessMap = this.wallTexture.glossMapTexture;
        this.wallMaterial.displacementScale = this.config.displacementScale;
        this.wallMaterial.displacementMap = this.wallTexture.displacementMapTexture;

        this.wallMaterial.alphaMap = this.wallTexture.alphaMap;
    }

    /**
     * Een image op canavas tekenen
     * @param ccontext canvas context
     * @param img afbeelding
     * @param x pos x
     * @param y pos y
     * @param width
     * @param height
     * @param deg rotatie
     * @param flip
     * @param flop
     * @param center
     */
    drawImage(ccontext, img, x, y, width, height, deg, flip, flop, center) {
        let flipScale = 1;
        let flopScale = 1;
        ccontext.save();

        if (typeof width === 'undefined') width = img.width;
        if (typeof height === 'undefined') height = img.height;
        if (typeof center === 'undefined') center = false;

        // Set rotation point to center of image, instead of top/left
        if (center) {
            x -= width / 2;
            y -= height / 2;
        }

        // Set the origin to the center of the image
        ccontext.translate(x + width / 2, y + height / 2);

        // Rotate the canvas around the origin
        let rad = 2 * Math.PI - deg * Math.PI / 180;
        ccontext.rotate(rad);

        // Flip/flop the canvas
        if (flip) flipScale = -1;
        else flipScale = 1;
        if (flop) flopScale = -1;
        else flopScale = 1;
        ccontext.scale(flipScale, flopScale);

        // Draw the image
        ccontext.drawImage(img, -width / 2, -height / 2, width, height);

        ccontext.restore();
    }

    // draw sprite Image on canvas and get the correct part of the image based on the brick number (bnr) and the rotation (rot) of the brick width is a given height is a crop base on the brick height times bnr
    drawSpriteImage(ccontext, img, x, y, width, height, deg, flip, flop, center, bnr, correctionWidth = 1, correctionHeight = 1) {
        let flipScale = 1;
        let flopScale = 1;
        ccontext.save();

        if (typeof width === 'undefined') width = img.width;
        if (typeof height === 'undefined') height = 154; //img.height;
        if (typeof center === 'undefined') center = false;

        // Set rotation point to center of image, instead of top/left
        if (center) {
            x -= width / 2;
            y -= height / 2;
        }

        // Set the origin to the center of the image
        ccontext.translate(x + width / 2, y + height / 2);

        // Rotate the canvas around the origin
        let rad = 2 * Math.PI - deg * Math.PI / 180;
        ccontext.rotate(rad);

        // Flip/flop the canvas
        if (flip) flipScale = -1;
        else flipScale = 1;
        if (flop) flopScale = -1;
        else flopScale = 1;
        ccontext.scale(flipScale, flopScale);

        let deltaY = Math.floor(bnr * 153.6);

        // Draw the image
        ccontext.drawImage( // drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)
            img,
            0, // The x-axis coordinate of the top left corner of the sub-rectangle of the source image to draw into the destination context.
            deltaY, // The y-axis coordinate of the top left corner of the sub-rectangle of the source image to draw into the destination context.
            img.width, // The width of the sub-rectangle of the source image to draw into the destination context.
            154, // The height of the sub-rectangle of the source image to draw into the destination context.
            -width * correctionWidth / 2, // The x-axis coordinate in the destination canvas at which to place the top-left corner of the source image.
            -height * correctionHeight / 2, // The y-axis coordinate in the destination canvas at which to place the top-left corner of the source image.
            width * correctionWidth, // The width to draw the image in the destination canvas. This allows scaling of the drawn image.
            height * correctionHeight // The height to draw the image in the destination canvas. This allows scaling of the drawn image.
        );

        ccontext.restore();
    }

    /**
     * Bepaal random waarde van 1 steen in de muur
     * @param x
     * @param y
     * @param count
     * @param brickCount
     * @returns {number}
     */
    drawBricksGetRandom(x, y, count, brickCount) {
        Math.seedrandom(this.config.randomSeed);

        const rnd = Math.floor(
            x +
            y +
            x * y +
            (Math.floor(Math.sin(x + y)) +
                Math.floor(Math.cos(x + y))) *
            (Math.floor(Math.cos(x)) +
                Math.floor(Math.atan(y)) *
                (Math.random() * x * y)) +
            count +
            this.config.randomSeed
        ) % brickCount;

        return rnd;
    }

    /**
     * 0-en toevoegen aan een string
     * @param num
     * @param totalLength
     * @returns {string}
     */
    addLeadingZeros(num, totalLength) {
        return String(num).padStart(totalLength, '0');
    }

    /**
     * Voor de kubus link en recht te teken clippen we de ctx
     * @param ctx
     * @param left
     */
    clipCtx(ctx, left) {
        let off = 0;
        if (!left) off = 2048;

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(off + 0, 0);
        ctx.lineTo(off + 2048, 0);
        ctx.lineTo(off + 2048, 2048);
        ctx.lineTo(off + 0, 2048);
        ctx.lineTo(off + 0, 0);
        ctx.closePath();

        /// define this Path as clipping mask
        ctx.clip();
    }

    /**
     * Teken 1 kant van de muur
     * @param leftSide links of rechts
     */
    drawWallSide(leftSide) {
        let scale = this.config.scale;

        this.clipCtx(this.wallTexture.colorMapCtx, leftSide);
        this.clipCtx(this.wallTexture.normalMapCtx, leftSide);
        this.clipCtx(this.wallTexture.roughMapCtx, leftSide);
        this.clipCtx(this.wallTexture.metalMapCtx, leftSide);
        this.clipCtx(this.wallTexture.glossMapCtx, leftSide);
        this.clipCtx(this.wallTexture.displacementMapCtx, leftSide);

        if (this.debugMode) {
            this.wallTexture.colorMapCtx.font = (150 * scale) + 'px Verdana';
            this.wallTexture.colorMapCtx.fillStyle = 'white';
        }

        let headerHueRotate = 0;
        let headerSaturation = 100;
        let headerBrightness = 1;
        let hueRotate = 0;
        let saturation = 100;
        let brightness = 1;
        let correctionWidth = 1;
        let correctionHeight = 1;

        for (let x = 0; x < this.BrickArrayMaxX; x++) {

            for (let y = 0; y < this.BrickArrayMaxY; y++) {

                let brickInfo = this.BrickArrayLeft[x][y];
                let sideOff = 0;
                if (!leftSide) {
                    brickInfo = this.BrickArrayRight[x][y];
                }

                let product = this.products[brickInfo.productRnd];

                let bnr = brickInfo.bnr;
                let rot = brickInfo.rot;

                // Beetje random rotatie toevoegen voor natuurlijk effect
                //rot = rot + 0.1 - brickInfo.bnr * 0.05;

                let flip = brickInfo.flip;
                let flop = brickInfo.flop;
                let brickImageWidth = 2048 * scale;
                if (brickInfo.header) {
                    brickImageWidth = 1024 * scale * 1.02;
                    if (brickInfo.height === 65) brickImageWidth = 1024 * scale;
                }
                let brickImageHeight = 512 * scale;

                // Corrigeer de offset van de steen
                let brickPosX = brickInfo.brickPosX - brickInfo.offX * scale;
                if (brickInfo.header) brickPosX = brickInfo.brickPosX - brickInfo.offX / 2 * scale;
                let brickPosY = brickInfo.brickPosY - brickInfo.offY * scale;

                if (brickInfo.header && brickInfo.height === 40) brickPosX = brickPosX - (this.settings.ribbon_joint * 8.3 * scale);

                if (leftSide && x === 0) {
                    if (brickInfo.header) {
                        brickImageWidth = brickImageWidth + 8 * scale; // Correctie zodat de stenen mooi aansluiten over de kant van de muur heen
                        //brickPosX = brickPosX + 12 * scale;
                    }

                    brickImageWidth = brickImageWidth + 30 * scale; // Correctie zodat de stenen mooi aansluiten over de kant van de muur heen

                    if (brickInfo.quarter) {
                        brickPosX = brickPosX - 12 * scale;
                    }
                }

                if (!leftSide && x === 0) {
                    if (brickInfo.quarter) {
                        brickPosX = brickPosX - (brickImageWidth / 4) + (this.settings.ribbon_joint * 8.3 * scale);
                        brickPosX = brickPosX + 40 * scale;
                        brickImageWidth = brickImageWidth + 25 * scale;
                    }

                    if (brickInfo.header) {
                        if (brickInfo.height === 40) {
                            brickPosX = brickPosX - 35 * scale;
                            brickImageWidth = brickImageWidth + 70 * scale;
                        } else if (brickInfo.height === 50) {
                            brickPosX = brickPosX - 15 * scale;
                        } else {
                            brickPosX = brickPosX - 45 * scale;
                            brickImageWidth = brickImageWidth + 35 * scale;
                        }
                    } else {
                        if (this.settings.bond !== 'Staande rollaag') {
                            brickPosX = brickPosX - 25 * scale; // Correctie zodat de stenen mooi aansluiten over de kant van de muur heen
                            brickImageWidth = brickImageWidth + 32 * scale;
                        }
                    }
                }

                if (brickInfo.header) brickPosX = brickPosX - 30 * scale;

                if (this.drawMode === 'seamless') {
                    if (this.settings.bond !== 'Staande rollaag') {
                        brickPosX = brickPosX + product.length;
                        brickPosY = brickPosY - this.settings.ribbon_joint * 1;
                    } else {
                        let offsetX = 120;
                        if (product.length > 210) offsetX = 60;
                        if (product.length > 240) offsetX = 60;
                        brickPosX = brickPosX + product.length - offsetX;
                        brickPosY = product.length + (this.settings.ribbon_joint * 4);
                        if (y !== 0) brickPosY = 2000;
                    }
                }

                if (product.renderData !== null && product.renderData !== '') {
                    headerHueRotate = product.renderData.headerHueRotate;
                    headerSaturation = product.renderData.headerSaturation;
                    headerBrightness = product.renderData.headerBrightness;
                    hueRotate = product.renderData.hueRotate;
                    saturation = product.renderData.saturation;
                    brightness = product.renderData.brightness;
                    correctionWidth = product.renderData.correctionWidth;
                    correctionHeight = product.renderData.correctionHeight;
                }

                // Omdat de correctionWidth + height nooit helemaal is uitgevoerd, wordt deze hier
                // hard naar 1 gezet. Dit is geen bug of "testcode". Staan laten dus! ;-)
                correctionWidth = 1;
                correctionHeight = 1;

                if (this.settings.bond !== 'Staande rollaag') {
                    correctionWidth -= 0.02;
                    correctionHeight += 0.022;
                }

                let isComplete = false;
                if (product.colorMapSpriteImg.complete) {
                    isComplete = true;
                }

                if (isComplete) {
                    if (brickInfo.header) {
                        this.wallTexture.colorMapCtx.filter = 'brightness(' + headerBrightness + ') saturate(' + headerSaturation + '%) hue-rotate(' + headerHueRotate + 'deg)';
                        this.wallTexture.glossMapCtx.filter = 'brightness(0.9)';
                    } else {
                        this.wallTexture.colorMapCtx.filter = 'brightness(' + brightness + ') saturate(' + saturation + '%) hue-rotate(' + hueRotate + 'deg)'; //brightness(1.05)
                        this.wallTexture.glossMapCtx.filter = 'brightness(1)';
                    }

                    let bbrn = bnr;

                    if (brickInfo.header) {
                        bbrn = bnr % 4;
                        try {
                            this.drawSpriteImage(this.wallTexture.colorMapCtx, product.headColorMapSpriteImg, brickPosX, brickPosY, brickImageWidth, brickImageHeight, rot, flip, flop, false, bbrn, correctionWidth, correctionHeight);
                            this.drawSpriteImage(this.wallTexture.normalMapCtx, product.headNormalMapSpriteImg, brickPosX, brickPosY, brickImageWidth, brickImageHeight, rot, flip, flop, false, bbrn, correctionWidth, correctionHeight);
                            this.drawSpriteImage(this.wallTexture.roughMapCtx, product.headRoughMapSpriteImg, brickPosX, brickPosY, brickImageWidth, brickImageHeight, rot, flip, flop, false, bbrn, correctionWidth, correctionHeight);
                            this.drawSpriteImage(this.wallTexture.metalMapCtx, product.headMetalMapSpriteImg, brickPosX, brickPosY, brickImageWidth, brickImageHeight, rot, flip, flop, false, bbrn, correctionWidth, correctionHeight);
                            this.drawSpriteImage(this.wallTexture.glossMapCtx, product.headGlossMapSpriteImg, brickPosX, brickPosY, brickImageWidth, brickImageHeight, rot, flip, flop, false, bbrn, correctionWidth, correctionHeight);
                            this.drawSpriteImage(this.wallTexture.displacementMapCtx, product.headDisplacementSpriteImg, brickPosX, brickPosY, brickImageWidth, brickImageHeight, rot, flip, flop, false, bbrn, correctionWidth, correctionHeight);
                        } catch (e) {

                        }
                    } else {
                        try {
                            this.drawSpriteImage(this.wallTexture.colorMapCtx, product.colorMapSpriteImg, brickPosX, brickPosY, brickImageWidth, brickImageHeight, rot, flip, flop, false, bbrn, correctionWidth, correctionHeight);
                            this.drawSpriteImage(this.wallTexture.normalMapCtx, product.normalMapSpriteImg, brickPosX, brickPosY, brickImageWidth, brickImageHeight, rot, flip, flop, false, bbrn, correctionWidth, correctionHeight);
                            this.drawSpriteImage(this.wallTexture.roughMapCtx, product.roughMapSpriteImg, brickPosX, brickPosY, brickImageWidth, brickImageHeight, rot, flip, flop, false, bbrn, correctionWidth, correctionHeight);
                            this.drawSpriteImage(this.wallTexture.metalMapCtx, product.metalMapSpriteImg, brickPosX, brickPosY, brickImageWidth, brickImageHeight, rot, flip, flop, false, bbrn, correctionWidth, correctionHeight);
                            this.drawSpriteImage(this.wallTexture.glossMapCtx, product.glossMapSpriteImg, brickPosX, brickPosY, brickImageWidth, brickImageHeight, rot, flip, flop, false, bbrn, correctionWidth, correctionHeight);
                            this.drawSpriteImage(this.wallTexture.displacementMapCtx, product.displacementSpriteImg, brickPosX, brickPosY, brickImageWidth, brickImageHeight, rot, flip, flop, false, bbrn, correctionWidth, correctionHeight);
                        } catch (e) {

                        }
                    }
                }

                if (this.debugMode) {
                    this.wallTexture.colorMapCtx.strokeStyle = 'rgba(255, 255, 255, 1)';
                    this.wallTexture.colorMapCtx.beginPath();
                    this.wallTexture.colorMapCtx.moveTo(brickInfo.brickPosX, brickInfo.brickPosY);
                    this.wallTexture.colorMapCtx.lineTo(brickInfo.brickPosX + brickInfo.brickPosLength, brickInfo.brickPosY);
                    this.wallTexture.colorMapCtx.lineTo(brickInfo.brickPosX + brickInfo.brickPosLength, brickInfo.brickPosY + brickInfo.brickPosHeight);
                    this.wallTexture.colorMapCtx.lineTo(brickInfo.brickPosX, brickInfo.brickPosY + brickInfo.brickPosHeight);
                    this.wallTexture.colorMapCtx.lineTo(brickInfo.brickPosX, brickInfo.brickPosY);
                    this.wallTexture.colorMapCtx.lineTo(brickInfo.brickPosX + brickInfo.brickPosLength, brickInfo.brickPosY + brickInfo.brickPosHeight);
                    let textText = x + ',' + y + ' ' + (brickInfo.bnr + 1); //+ "-" + brickInfo.product_number
                    if (brickInfo.header) textText += ' H';
                    if (brickInfo.quarter) textText += ' k';

                    this.wallTexture.colorMapCtx.fillText(
                        textText,
                        brickInfo.brickPosX + sideOff + 40 * scale,
                        brickInfo.brickPosY + 120 * scale
                    );
                }
            }
        }

        if (this.debugMode) {
            this.wallTexture.colorMapCtx.strokeStyle = 'rgb(1,180,255)';
            this.wallTexture.colorMapCtx.beginPath();
            this.wallTexture.colorMapCtx.moveTo(0, this.maxTopBricks - 10);
            this.wallTexture.colorMapCtx.lineTo(4096, this.maxTopBricks - 10);
            this.wallTexture.colorMapCtx.moveTo(0, this.maxTopBricks);
            this.wallTexture.colorMapCtx.lineTo(4096, this.maxTopBricks);
            this.wallTexture.colorMapCtx.moveTo(0, this.maxTopBricks + 10);
            this.wallTexture.colorMapCtx.lineTo(4096, this.maxTopBricks + 10);
            this.wallTexture.colorMapCtx.stroke();
        }

        this.wallTexture.colorMapCtx.restore();
        this.wallTexture.normalMapCtx.restore();
        this.wallTexture.roughMapCtx.restore();
        this.wallTexture.metalMapCtx.restore();
        this.wallTexture.glossMapCtx.restore();
        this.wallTexture.displacementMapCtx.restore();
    }

    /**
     * Voeg materiaal tekenen op canvas
     */
    drawGrout() {
        let count = 0;
        let scale = this.config.scale; //control.scale;
        let antiScale = 10;
        let maxRotation = this.config.maxRotation;
        let rot = 0;
        let baseOff = -200;
        let baseOffX = -50;
        let bh = this.config.seamX + this.config.seamCorrectX; // control.seamX + control.seamCorrectX; // 344
        let bw = this.config.seamY + this.config.seamCorrectY; //control.seamY + control.seamCorrectY; // 1024
        let offA = this.config.offsetBricks;

        this.wallTexture.colorMapCtx.fillStyle = this.config.jointColor; //'#AAAAAA';
        this.wallTexture.colorMapCtx.fillRect(0, 0, 2048 * 2, 2048);
        this.wallTexture.normalMapCtx.fillStyle = '#777777';
        this.wallTexture.normalMapCtx.fillRect(0, 0, 2048 * 2, 2048);
        this.wallTexture.roughMapCtx.fillStyle = '#777777';
        this.wallTexture.roughMapCtx.fillRect(0, 0, 2048 * 2, 2048);
        this.wallTexture.metalMapCtx.fillStyle = '#000000';
        this.wallTexture.metalMapCtx.fillRect(0, 0, 2048 * 2, 2048);
        this.wallTexture.glossMapCtx.fillStyle = '#000000';
        this.wallTexture.glossMapCtx.fillRect(0, 0, 2048 * 2, 2048);
        this.wallTexture.displacementMapCtx.fillStyle = '#000000';
        this.wallTexture.displacementMapCtx.fillRect(0, 0, 2048 * 2, 2048);

        for (let y = -1; y < 10 * antiScale; y++) {
            for (let x = -1; x < 4 * antiScale; x++) {
                count++;
                let bnr = this.drawBricksGetRandom(x, y, count);

                rot = bnr * maxRotation; //control.maxRotation;
                if (bnr % 2 === 1) rot = bnr * maxRotation - 1; //control.maxRotation

                let off = 0;
                if (y % 2 === 0) off = -offA;
                off += baseOff;
                this.wallTexture.colorMapCtx.fillRect(x * bw * scale + off * scale, baseOffX + y * bh * scale, 2048 * scale, 522 * scale);
                this.wallTexture.colorMapCtx.save();
                this.wallTexture.colorMapCtx.globalCompositeOperation = 'multiply';
                this.drawImage(this.wallTexture.colorMapCtx, this.wallTexture.grout.colorMapImg, x * bw * scale + off * scale, baseOffX + y * bh * scale, 2048 * scale, 522 * scale, 0);
                this.wallTexture.colorMapCtx.restore();
                this.drawImage(this.wallTexture.normalMapCtx, this.wallTexture.grout.normalMapImg, x * bw * scale + off * scale, baseOffX + y * bh * scale, 2048 * scale, 522 * scale, 0);
            }
        }
    }

    /**
     * De stenen array vullen ten behoeven van het tekenen van de muur
     */
    fillBrickArray() {

        let count = 0;
        let product, bnr, productRnd;
        // Spiegelen?
        let flip = false;
        let flop = false;
        let realToPixelRationX = 7.2; // Omrekenen van mm naar px
        let realToPixelRationY = 7.2; // Omrekenen van mm naar px
        let brickImageWidth = 2048; // Alle afbeeldingen zijn 2048px behalve kopse kanten
        let scale = this.config.scale;
        let rot = 0;

        let productsLength = this.products.length; // Zijn er producten?
        if (productsLength > 0) {
            let baseProduct = this.products[0]; // Basis product tbv breed en hoog, een muur kent altijd maar 1 formaat.

            // Uitrekenen van aantal stenen in muur
            let baseW = baseProduct.length * realToPixelRationX * scale + this.settings.butt_joint * scale;
            let baseH = baseProduct.height * realToPixelRationY * scale + this.settings.ribbon_joint * scale;
            this.BrickArrayMaxX = Math.floor(2048 / baseW) + 3;
            this.BrickArrayMaxY = Math.floor(2048 / baseH) + 1;

            if (this.settings.bond === 'Kruis' || this.settings.bond === 'Staand') {
                this.BrickArrayMaxX = Math.floor((2048 / baseW) + 250 * scale);
            }
            if (this.settings.bond === 'Wild') {
                this.BrickArrayMaxX = Math.floor((2048 / baseW) + 125 * scale);
            }
            if (this.settings.bond === 'Staande rollaag') {
                // Staande laag de waardes omdraaien
                this.BrickArrayMaxX = Math.floor(2048 / baseH) + 1;
                this.BrickArrayMaxY = Math.floor(2048 / baseW) + 2;
            }

            let stepLeftX = 0;
            let stepRightX = 0;
            let stepLeftY = 0;

            let leftFirstBnr = 0;
            let leftFirstProductRnd = 0;

            // Hoogste steen in muur vinden
            let extraBricks = 1;
            this.maxTopBricks = -1;
            while (this.maxTopBricks < 1) {
                if (this.settings.bond !== 'Staande rollaag') {
                    this.maxTopBricks = 2048 - ((this.BrickArrayMaxY - extraBricks) * (baseProduct.height * realToPixelRationY * scale) + ((this.BrickArrayMaxY - extraBricks - 1) * (this.settings.ribbon_joint * realToPixelRationY * scale)));
                } else {
                    this.maxTopBricks = 2048 - ((this.BrickArrayMaxY - extraBricks) * (baseProduct.length * realToPixelRationY * scale) + ((this.BrickArrayMaxY - extraBricks - 1) * (this.settings.ribbon_joint * realToPixelRationY * scale)));
                }
                extraBricks++;
            }
            // Clipping plane hoogte bepalen
            this.topOffRendering = 0.317 - (((this.maxTopBricks) / 2048) * 0.6);
            //if (this.settings.bond === 'Staande rollaag') this.topOffRendering = 0.317  - (((this.maxTopBricks) / 2048) * 0.6) - 0.008;

            this.BrickArrayLeft = [...new Array(this.BrickArrayMaxX)].map(elem => new Array(this.BrickArrayMaxY));
            this.BrickArrayRight = [...new Array(this.BrickArrayMaxX)].map(elem => new Array(this.BrickArrayMaxY));

            for (let y = 0; y < this.BrickArrayMaxY; y++) {
                stepLeftX = 0;
                stepRightX = 0;

                leftFirstBnr = 0;
                leftFirstProductRnd = 0;

                let headerCountLeft = 0; // Nodig bij wildverband
                let headerCountRight = 0; // Nodig bij wildverband
                let wideCountLeft = 0; // Nodig bij wildverband
                let wideCountRight = 0; // Nodig bij wildverband

                for (let x = 0; x < this.BrickArrayMaxX; x++) {
                    count++;
                    const brickRng = new Math.seedrandom(this.config.randomSeed + y + x + count);

                    // Returns the randomly chosen index or -1 if the array is empty.
                    productRnd = this.chooser.chooseWeightedIndex(this.percentages, this.config.randomSeed + y + x + count);

                    if (productRnd === -1) productRnd = 0; // Altijd uitgaan van minimaal 1 product

                    product = this.products[productRnd];
                    bnr = this.drawBricksGetRandom(x, y, count, product.brickCount);

                    let isHeaderLeft = false;
                    let isHeaderRight = false;
                    let isQuarterLeft = false;
                    let isQuarterRight = false;

                    flip = x * y * bnr % 2 === 1;
                    flop = y + y * bnr % 2 === 0;

                    let plLeft = baseProduct.length;
                    let plRight = baseProduct.length;

                    let phLeft = baseProduct.height;
                    let phRight = baseProduct.height;

                    if (this.settings.bond === 'Staande rollaag') {
                        // Staande laag de waardes omdraaien
                        plLeft = baseProduct.height;
                        plRight = baseProduct.height;

                        phLeft = baseProduct.length;
                        phRight = baseProduct.length;
                    }

                    let offX = (2048 - plLeft * realToPixelRationX) / 2;
                    let offY = (512 - phLeft * realToPixelRationX) / 2;

                    if (this.drawMode === 'seamless') {
                        // Als seamless dan eerst en laatste steen hetzelfde maken
                        if (baseProduct.length - stepLeftX > 0) {
                            leftFirstBnr = bnr;
                            leftFirstProductRnd = productRnd;
                        } else if (stepLeftX > 2048) {
                            if (this.settings.bond !== 'Staande rollaag') {
                                bnr = leftFirstBnr;
                                productRnd = leftFirstProductRnd;
                                product = this.products[productRnd];
                            }
                        }
                    }

                    if (this.settings.bond === 'Halfsteens') {
                        if (x === 0) {
                            if (y % 2 === 0) {
                                plLeft = product.width;
                                isHeaderLeft = true;
                            } else {
                                plRight = product.width;
                                isHeaderRight = true;
                            }
                        }
                    }

                    if (this.settings.bond === 'Blok') {
                        if (x === 0) {
                            if (y % 4 === 0 || y % 4 === 1) {
                                plLeft = product.width;
                                isHeaderLeft = true;
                            } else {
                                plRight = product.width;
                                isHeaderRight = true;
                            }
                        }
                    }

                    if (this.settings.bond === 'Ketting') {
                        if (y % 2 === 0) {
                            if (x === 0 || x === 1) {
                                plLeft = product.width;
                                isHeaderLeft = true;
                            }
                            if (x === 0) {
                                plRight = product.length * 0.75;
                                isQuarterRight = true;
                            }
                        } else {
                            if (x === 0 || x === 1) {
                                plRight = product.width;
                                isHeaderRight = true;
                            }
                            if (x === 0) {
                                plLeft = product.length * 0.75;
                                isQuarterLeft = true;
                            }
                        }
                    }

                    if (this.settings.bond === 'Kruis') {
                        if (y % 2 === 0) {
                            plLeft = product.width;
                            isHeaderLeft = true;

                            if (x === 0) {
                                plRight = product.length * 0.75;
                                isQuarterRight = true;
                            }
                            if (x === 1 && y % 4 === 0) {
                                plRight = product.width;
                                isHeaderRight = true;
                            }
                        } else {
                            plRight = product.width;
                            isHeaderRight = true;
                            if (x === 0) {
                                plLeft = product.length * 0.75;
                                isQuarterLeft = true;
                            }
                            if (x === 1 && y % 4 === 1) {
                                plLeft = product.width;
                                isHeaderLeft = true;
                            }
                        }
                    }

                    if (this.settings.bond === 'Staand') {
                        if (y % 2 === 0) {
                            plLeft = product.width;
                            isHeaderLeft = true;

                            if (x === 0) {
                                plRight = product.length * 0.75;
                                isQuarterRight = true;
                            }
                        } else {
                            plRight = product.width;
                            isHeaderRight = true;
                            if (x === 0) {
                                plLeft = product.length * 0.75;
                                isQuarterLeft = true;
                            }
                        }
                    }

                    if (this.settings.bond === 'Wild') {
                        // Elke even rij moet starten met een klezoor. Elke andere rij start willekeurig met een kop of strek.
                        // In de muur worden alleen koppen en strekken gebruikt, op willekeurige wijze.
                        // Er mogen maximum 4 strekken naast elkaar geplaatst worden.
                        // Er mogen maximum 2 koppen naast elkaar geplaatst worden.
                        // (Er mag maximaal een trap van 5 à 6 tredes ontstaan.)

                        if (x === 0) {
                            // Eerste steen bepalen
                            if (y % 2 === 0) {
                                // Even rijen = starten met een klezoor
                                isQuarterLeft = true;
                                plLeft = product.length * 0.75;

                                isHeaderRight = true;
                                plRight = product.width;

                                // Om te voorkomen dat er gestart wordt met 2 koppen
                                // zetten we de headerCountRight nu al op 2.
                                headerCountRight = 2;
                            } else {
                                // Oneven rijen = starten met een kop
                                isHeaderLeft = true;
                                plLeft = product.width;

                                // Om te voorkomen dat er gestart wordt met 2 koppen
                                // zetten we de headerCountLeft nu al op 2.
                                headerCountLeft = 2;

                                isQuarterRight = true;
                                plRight = product.length * 0.75;

                            }
                        } else {
                            // Vervolgstenen bepalen
                            if (brickRng() < 0.25) {
                                if (headerCountLeft < 2) {
                                    // Kop
                                    isHeaderLeft = true;
                                    plLeft = product.width;
                                    headerCountLeft++;
                                    wideCountLeft = 0;
                                } else {
                                    // Teveel koppen naast elkaar, toch een strek
                                    plLeft = product.length;
                                    wideCountLeft++;
                                    headerCountLeft = 0;
                                }

                                if (headerCountRight < 2) {
                                    // Kop
                                    isHeaderRight = true;
                                    plRight = product.width;
                                    headerCountRight++;
                                    wideCountRight = 0;
                                } else {
                                    // Teveel koppen naast elkaar, toch een strek
                                    plRight = product.length;
                                    wideCountRight++;
                                    headerCountRight = 0;
                                }
                            } else {
                                if (wideCountLeft < 5) {
                                    // Strek
                                    plLeft = product.length;
                                    wideCountLeft++;
                                    headerCountLeft = 0;
                                } else {
                                    // Teveel strekken naast elkaar, toch een kop
                                    isHeaderLeft = true;
                                    plLeft = product.width;
                                    headerCountLeft++;
                                    wideCountLeft = 0;
                                }

                                if (wideCountRight < 5) {
                                    // Strek
                                    plRight = product.length;
                                    wideCountRight++;
                                    headerCountRight = 0;
                                } else {
                                    // Teveel strekken naast elkaar, toch een kop
                                    isHeaderRight = true;
                                    plRight = product.width;
                                    headerCountRight++;
                                    wideCountRight = 0;
                                }
                            }
                        }
                    }

                    if (this.settings.bond === 'Staande rollaag') {
                        rot = 90;
                    }

                    stepLeftX += plLeft * realToPixelRationX * scale;
                    stepLeftY += phLeft * realToPixelRationY * scale;

                    let bnrLeft = bnr;
                    let bnrRight = bnr;
                    if (isHeaderLeft) bnrLeft = this.drawBricksGetRandom(x, y, count, 4) + product.brickCount;
                    if (isHeaderRight) bnrRight = this.drawBricksGetRandom(x, y, count, 4) + product.brickCount;

                    this.BrickArrayLeft[x][y] = {
                        'productRnd': productRnd,
                        'product_number': product.product_number,
                        'bnr': bnrLeft,
                        'rot': rot,
                        'flip': flip,
                        'flop': flop,
                        'height': product.height,
                        'offX': offX,
                        'offY': offY,
                        'header': isHeaderLeft,
                        'quarter': isQuarterLeft,
                        'brickPosX': 2048 - stepLeftX,
                        'brickPosY': 2048 - ((y + 1) * (phLeft * realToPixelRationY * scale) + (y * (this.settings.ribbon_joint * realToPixelRationY * scale))),
                        'brickPosLength': plLeft * realToPixelRationX * scale,
                        'brickPosHeight': phLeft * realToPixelRationY * scale,
                    };

                    this.BrickArrayRight[x][y] = {
                        'productRnd': productRnd,
                        'product_number': product.product_number,
                        'bnr': bnrRight,
                        'rot': rot,
                        'flip': flip,
                        'flop': flop,
                        'height': product.height,
                        'offX': offX,
                        'offY': offY,
                        'header': isHeaderRight,
                        'quarter': isQuarterRight,
                        'brickPosX': 2048 + stepRightX,
                        'brickPosY': 2048 - ((y + 1) * (phRight * realToPixelRationY * scale) + (y * (this.settings.ribbon_joint * realToPixelRationY * scale))),
                        'brickPosLength': plRight * realToPixelRationX * scale,
                        'brickPosHeight': phRight * realToPixelRationY * scale,
                    };

                    stepLeftX += this.settings.butt_joint * realToPixelRationX * scale;
                    stepRightX += (plRight * realToPixelRationX * scale) + (this.settings.butt_joint * realToPixelRationX * scale);
                }
            }
        }
    }

    /**
     * Muurtje tekenen
     */
    drawBricks() {

        this.logoPlane.position.z = 0.505 + this.config.displacementScale;
        this.doDrawBricks--;

        this.drawGrout();
        this.drawWallSide(true);
        this.drawWallSide(false);

        this.wallTexture.colorMapTexture.needsUpdate = true;
        this.wallTexture.normalMapTexture.needsUpdate = true;
        this.wallTexture.roughMapTexture.needsUpdate = true;
        this.wallTexture.metalMapTexture.needsUpdate = true;
        this.wallTexture.glossMapTexture.needsUpdate = true;
        this.wallTexture.displacementMapTexture.needsUpdate = true;

        if (this.doDrawBricks === 0) {
            this.setModelReady();
        }
    }

    /**
     * Alles geladen dan muur model tonen
     */
    setModelReady() {
        if (this.modelReady) {
            this.model.visible = true;
            this.baseCube.visible = false;
            this.showHideLoading(false);
            if (this.drawMode !== 'product-render') {
                if (this.shadowmaterial !== undefined) this.shadowmaterial.opacity = 1;
            }
            if (this.drawMode === 'home') this.start();
        } else {
            this.model.visible = false;
            this.baseCube.visible = true;
            if (this.shadowmaterial !== undefined) this.shadowmaterial.opacity = 0;
        }
    }

    /**
     * Zijn alle texturen geladen?
     */
    drawBricksWhenLoaded() {
        this.showHideLoading(true);
        if (this.wallTexture.loadingCount < 1 && this.checkAllLoadingComplete()) {
            setTimeout(() => {
                this.modelReady = true;
                this.doDrawBricks = 1;
                if (this.domInfo.productViewerImage !== undefined) {
                    setTimeout(() => {
                        this.domInfo.canvas.style.opacity = '1';
                        this.mouseMove(0, 0);
                        this.showHideLoading(false);
                    }, 325);
                    setTimeout(() => {
                        this.domInfo.productViewerImage.style.opacity = '0';
                        this.showHideLoading(false);
                    }, 350);
                }
                this.setModelReady();
            }, 100);
        } else {
            this.modelReady = false;
            this.setModelReady();
            setTimeout(() => {
                this.drawBricksWhenLoaded();
            }, 250);
        }
    }

    /**
     * 1 textuur geladen event
     */
    brickTextureLoaded(spriteImage) {
        if (spriteImage === undefined) return;

        if (spriteImage.src.includes('-small')) {
            // Vervang de -small door niets zodat de grote afbeelding geladen wordt
            setTimeout(() => {
                spriteImage.src = spriteImage.src.replace('-small', '');
                spriteImage.onload = () => {
                    setTimeout(() => {
                        this.doDrawBricks = 2;
                    }, 100);
                }
            }, 300);
        }

        this.wallTexture.loadingCount--;
        if (this.wallTexture.loadingCount < 1) {
            this.drawBricksWhenLoaded();
        }
    }

    adjustLightOnColor() {
        let isWhite = false;

        for (let i = 0; i < this.products.length; i++) {
            //TODO dit mooier maken is nu op kleur naam niet heel elegant

            if (this.products[i].name.toLowerCase().includes('wit')) {
                if (this.products.length === 1) isWhite = true;
                this.products[i].isWhite = true;
                this.products[i].renderData.brightness -= 0.05;
                this.products[i].renderData.headerBrightness -= 0.05;
                this.products[i].renderData.saturation = Math.max(0, this.products[i].renderData.saturation - 10);
                this.products[i].renderData.headerSaturation = Math.max(0, this.products[i].renderData.headerSaturation - 10);
            } else {
                this.products[i].isWhite = false;
            }
        }

        if (this.lights.light !== undefined) {
            if (isWhite) {
                // Bij witte stenen belichting aanpassen
                this.lights.light.color = new THREE.Color(0xf0f4fa);
                this.lights.light.intensity = 5 * 0.8;

                this.lights.lightb.color = new THREE.Color(0x003591);
                this.lights.lightb.intensity = 30.35 * 0.5;

                this.lights.lightc.color = new THREE.Color(0xffffff);
                this.lights.lightc.intensity = 3.0;

                this.lights.lighta.color = new THREE.Color(0xc5dbfc);
                this.lights.lighta.intensity = 0.4;

                if (this.wallMaterial !== undefined) {
                    this.wallMaterial.roughness = 1;
                    this.wallMaterial.metalness = 0;
                }
            } else {
                this.lights.light.color = new THREE.Color(0xe6dbdb); // 0xe6dbdb --- rooder
                this.lights.light.intensity = 6;

                this.lights.lightb.color = new THREE.Color(0x003591); // 0x003591
                this.lights.lightb.intensity = 25.35;

                this.lights.lightc.color = new THREE.Color(0xfffee9); // 0xfef648
                this.lights.lightc.intensity = 8.13;

                this.lights.lighta.color = new THREE.Color(0xffffff); // 0xf2d7d7
                this.lights.lighta.intensity = 0.15;

                if (this.wallMaterial !== undefined) {
                    this.wallMaterial.roughness = 0.75;
                    this.wallMaterial.metalness = 0.35;
                }
            }
        }
    }

    /**
     * Data van 1 steen initen
     * @param product_number
     * @param brickcount
     * @param brick
     * @returns {{}}
     */
    initProduct(product_number, brickcount, brick) {
        this.loadRetrys = 0;

        let product = {};
        product.name = 'name';
        product.brickCount = brickcount;
        product.product_number = product_number;
        product.color = 'color';
        product.finish = 'finish';
        product.format = 'format';
        product.averageColor = '#cccccc';
        product.renderData = null;
        product.length = parseInt('210');
        product.width = parseInt('100');
        product.height = parseInt('50');
        product.naamcode = 'naamcode';
        product.naamcolor = 'naamcolor';
        product.naamshort = 'naamshort';
        product.objectID = '1';
        product.serie = 'serie';
        product.brickCount = brickcount;

        if (brick !== undefined) {
            product.name = brick.name;
            product.color = brick.color;
            product.finish = brick.finish;
            product.format = brick.format;
            product.length = parseInt('210');
            product.width = parseInt('100');
            product.height = parseInt('50');
            product.naamcode = brick.naamcode;
            product.naamcolor = brick.naamcolor;
            product.naamshort = brick.naamshort;
            product.objectID = brick.objectID;
            product.serie = brick.serie;
            product.brickCount = brickcount;

            product.averageColor = brick.averageColor;
            if (brick.renderData !== undefined) {
                try {
                    let renderData = JSON.parse(brick.renderData);
                    product.renderData = renderData;
                } catch (error) {
                    product.renderData = {
                        'headerHueRotate': 0,
                        'headerSaturation': 100,
                        'headerBrightness': 1,
                        'hueRotate': 0,
                        'saturation': 100,
                        'brightness': 1,
                        'correctionHeight': 0.97,
                        'correctionWidth': 0.967
                    };
                    // this.handleError('renderData 4 error, ' + error);
                    console.error(error);
                }
            }

            let productSize = window.Collection.getProductSizeFromName(brick.size);
            product.length = productSize.length;
            product.width = productSize.width;
            product.height = productSize.height;
            if (product.renderData !== null) {
                if (product.renderData.correctionWidth === undefined) {
                    product.renderData.correctionWidth = productSize.correctionWidth;
                    product.renderData.correctionHeight = productSize.correctionHeight;
                }
            }
        }

        if (
            product.product_number === '101240' ||
            product.product_number === '101415' ||
            product.product_number === '101717' ||
            product.product_number === '105241' ||
            product.product_number === '105430' ||
            product.product_number === '105510'
        ) {
            product.brickCount = 12;
        }

        if (
            product.product_number === '112140' ||
            product.product_number === '102640' ||
            product.product_number === '111815' ||
            product.product_number === '105535' ||
            product.product_number === '105420'
        ) {
            product.brickCount = 30;
        }

        if (
            product.product_number === '105242' ||
            product.product_number === '105719' ||
            product.product_number === '105710'
        ) {
            product.brickCount = 24;
        }

        product.brickSpriteTextures = this.loadBrickTexturesBySprite(product);

        return product;
    }

    /**
     * Is alles helemaal geladen?
     * @returns {boolean}
     */
    checkAllLoadingComplete() {
        let allComplete = true;

        if (this.products.length === 0) return false;

        if (!this.wallTexture.grout.colorMapImg.complete) allComplete = false;
        if (!this.wallTexture.grout.normalMapImg.complete) allComplete = false;

        for (let i = 0; i < this.products.length; i++) {
            if (!this.checkProductLoadingComplete(this.products[i])) allComplete = false;
        }

        return allComplete;
    }

    /**
     * Is 1 product helemaal geladen?
     * @param product
     * @returns {boolean}
     */
    checkProductLoadingComplete(product) {
        let allComplete = true;

        if (!product.colorMapSpriteImg.complete) allComplete = false;
        if (!product.normalMapSpriteImg.complete) allComplete = false;
        if (!product.roughMapSpriteImg.complete) allComplete = false;
        if (!product.metalMapSpriteImg.complete) allComplete = false;
        if (!product.glossMapSpriteImg.complete) allComplete = false;
        if (!product.displacementSpriteImg.complete) allComplete = false;

        if (!product.headMetalMapSpriteImg.complete) allComplete = false;
        if (!product.headGlossMapSpriteImg.complete) allComplete = false;
        if (!product.headColorMapSpriteImg.complete) allComplete = false;
        if (!product.headNormalMapSpriteImg.complete) allComplete = false;
        if (!product.headRoughMapSpriteImg.complete) allComplete = false;
        if (!product.headDisplacementSpriteImg.complete) allComplete = false;

        return allComplete;
    }

    loadSpriteImg(spriteImage, imgName, product) {
        this.wallTexture.loadingCount = this.wallTexture.loadingCount + 1;
        let wait = 200;
        let self = this;
        if (this.loadMode === 'error') wait = 15000;
        if (this.loadMode === 'home') wait = 5000;

        let smallUrlPart = '-small';

        setTimeout(() => {
            spriteImage.onload = this.brickTextureLoaded(spriteImage);
        }, wait);

        spriteImage.onerror = (event) => {
            // Retry after 500ms if error
            if (self.loadRetrys < 10) {
                self.wallTexture.loadingCount--;

                setTimeout(() => {
                    spriteImage.src = self.brickSpriteTexturePath + '/' + product.product_number + '/' + imgName + '.webp?cache=' + new Date().getTime();

                }, 100);
                self.loadRetrys++;
            }
        };

        if (this.loadMode === 'home') {
            setTimeout(() => {
                spriteImage.src = this.brickSpriteTexturePath + '/' + product.product_number + '/' + imgName + '.webp';
            }, wait * 0.9);
        } else {
            spriteImage.src = this.brickSpriteTexturePath + '/' + product.product_number + '/' + imgName + smallUrlPart + '.webp';
        }
    }

    loadBrickTexturesBySprite(product) {
        product.metalMapSpriteImg = new Image();
        product.glossMapSpriteImg = new Image();
        product.colorMapSpriteImg = new Image();
        product.normalMapSpriteImg = new Image();
        product.roughMapSpriteImg = new Image();
        product.displacementSpriteImg = new Image();

        this.loadSpriteImg(product.colorMapSpriteImg, 'color', product);
        this.loadSpriteImg(product.normalMapSpriteImg, 'normal', product);
        this.loadSpriteImg(product.roughMapSpriteImg, 'rough', product);
        this.loadSpriteImg(product.metalMapSpriteImg, 'metal', product);
        this.loadSpriteImg(product.glossMapSpriteImg, 'gloss', product);
        this.loadSpriteImg(product.displacementSpriteImg, 'displacement', product);

        product.headMetalMapSpriteImg = new Image();
        product.headGlossMapSpriteImg = new Image();
        product.headColorMapSpriteImg = new Image();
        product.headNormalMapSpriteImg = new Image();
        product.headRoughMapSpriteImg = new Image();
        product.headDisplacementSpriteImg = new Image();

        this.loadSpriteImg(product.headColorMapSpriteImg, 'head-color', product);
        this.loadSpriteImg(product.headNormalMapSpriteImg, 'head-normal', product);
        this.loadSpriteImg(product.headRoughMapSpriteImg, 'head-rough', product);
        this.loadSpriteImg(product.headMetalMapSpriteImg, 'head-metal', product);
        this.loadSpriteImg(product.headGlossMapSpriteImg, 'head-gloss', product);
        this.loadSpriteImg(product.headDisplacementSpriteImg, 'head-displacement', product);

        return product;
    }

    /**
     * Extra muur (los van steen) texturen laden
     */
    loadWallTextures() {
        // Transparantie laden
        this.wallTexture.alphaMap = this.loader.load('/application/themes/z_theme/assets/images/configurator-alpha.webp');

        this.wallTexture.grout.colorMapImg = new Image;
        this.wallTexture.grout.colorMapImg.src = this.mortelTexturePath + '/mortel-color-m.webp';
        this.wallTexture.grout.normalMapImg = new Image;
        this.wallTexture.grout.normalMapImg.src = this.mortelTexturePath + '/mortel-normal.webp';
    }

    encode(input) {
        return Buffer.from(input, 'utf-8').toString('base64');
    }

    decode(input) {
        return Buffer.from(input, 'base64').toString('utf-8');
    }

    async sendDownloadTexturesMail(email) {
        let data = {};

        data.settings = this.settings;
        data.config = this.config;
        data.percentages = this.percentages;
        data.validTo = new Date(new Date().setDate(new Date().getDate() + 1));
        data.products = [];

        this.products.forEach(product => {
            data.products.push(product.product_number);
        });

        data = JSON.stringify(data);

        try {
            await fetch(window.saveTextureRequestAPI, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    hash: this.encode(data),
                })
            });
        } catch {

        }
    }

}
