// Bootstrap
require('../../../../../application/blocks/site_search/assets/basic_search/basic_search.js');
require('../../../../../application/blocks/site_search_extended/assets/basic_search/basic_search.js');

import { Collection } from './Collection.js';

window.Collection = new Collection();

function importProductViewersManager () {
    // Perform the dynamic import synchronously
    const module = require('./ProductViewersManager.js');
    window.productViewersManager = new module.ProductViewersManager();
}

if (!document.querySelector('.rodruza-configurator')) {
    importProductViewersManager();
}

// Main ------------------------------------------------------------------------------------------
//

{
    window.addEventListener('DOMContentLoaded', function () {
        randomHomepageVideo();
        initUi();
        zlMessage();
    });

    let rndVideo = 0;

    function randomHomepageVideo () {
        if (window.innerWidth > 768) {
            rndVideo = getNewRand(rndVideo, 5) + 1;
            let homeVideo = document.getElementsByClassName('home-header__video__object')[0];
            if (homeVideo === undefined) return;
            homeVideo.setAttribute('src', '/application/files/rodruza/video/homepage0' + rndVideo + '.mp4');
            homeVideo.setAttribute('type', 'video/mp4');

            homeVideo.onplay = function (e) {
                homeVideo.style.opacity = 1;
            };

            homeVideo.onended = function (e) {
                homeVideo.style.opacity = 0;
                setTimeout(randomHomepageVideo, 1500);
            };
        }
    }

    function getNewRand (oldRand, max) {
        const newRand = Math.floor(Math.random() * (max + 1));
        return (newRand === oldRand ? getNewRand(newRand, max) : newRand);
    }

    let scrollCounter = 0;
    let lastScrollMoment = new Date().getTime();

    function scrollEventBlock () {

        window.addEventListener('scroll', function () {
            const now = new Date().getTime();
            if (lastScrollMoment - now < -3000) {
                lastScrollMoment = new Date().getTime();
                scrollCounter++;
                if (scrollCounter > 3) {
                    scrollCounter = 0;
                    const scrollPopups = document.querySelectorAll('.scrollpopup');
                    scrollPopups.forEach(function (scrollPopup) {
                        scrollPopup.classList.add('active');
                    });
                }
            }

        });

        // add click event to close the scrollpopup on all element width the class scrollpopup__close
        const scrollPopupClose = document.querySelectorAll('.scrollpopup__close');
        scrollPopupClose.forEach(function (scrollPopupClose) {
            scrollPopupClose.addEventListener('click', function (event) {
                const scrollPopups = document.querySelectorAll('.scrollpopup');
                scrollPopups.forEach(function (scrollPopup) {
                    scrollPopup.classList.remove('active');
                });
            });
        });
    }

    let newHover = false;

    function initUi () {
        // voeg een click event toe aan elke .nav > li > a
        console.log('initUi');

        scrollEventBlock();

        const navItems = document.querySelectorAll('.nav > li > a');
        navItems.forEach(function (navItem) {
            if (window.innerWidth < 850) navItem.removeAttribute('href');
            navItem.addEventListener('mouseover', function (event) {
                event.stopImmediatePropagation();
                navItems.forEach(function (navItem) {
                    //navItem.parentElement.querySelector('ul').classList.remove('hover');
                    navItem.parentElement.classList.remove('hover');
                });
                navItem.parentElement.classList.add('hover');
            });
            navItem.addEventListener('click', function (event) {

                event.stopImmediatePropagation();
                navItems.forEach(function (navItem) {
                    //navItem.parentElement.querySelector('ul').classList.remove('hover');
                    navItem.parentElement.classList.remove('hover');
                });
                navItem.parentElement.classList.add('hover');
            });
        });

        const navSubItems = document.querySelectorAll('.nav > li > ul');
        navSubItems.forEach(function (navSubItem) {
            navSubItem.addEventListener('mouseover', function (event) {
                newHover = true;
            });
            navSubItem.addEventListener('mouseleave', function (event) {
                newHover = false;
                setTimeout(function () {
                    if (!newHover) navSubItem.parentElement.classList.remove('hover');
                }, 600);
            });
        });

        if (document.querySelectorAll('.page-template-home').length) {
            randomHomepageVideo();
        }


        setTimeout(function () {
            let searchInput = document.getElementsByClassName('main-search-input');
            if (searchInput.length > 0) {
                searchInput[0].addEventListener('input', function (event) {
                    let searchInput2 = document.getElementsByClassName('extended-search-input');
                    if (searchInput2.length > 0) {
                        searchInput2[0].value = searchInput[0].value;
                        //trigger input event on searchInput2
                        let event = new Event('input', {
                            bubbles: true,
                            cancelable: true,
                        });
                        searchInput2[0].dispatchEvent(event);
                    }
                });
            }
        }, 500);
    }

    function zlMessage () {
        console.log(
            ' _____     _     _           _ _      _     _\n' +
            '|__  /   _(_) __| | ___ _ __| (_) ___| |__ | |_\n' +
            '  / / | | | |/ _` |/ _ \\ \'__| | |/ __| \'_ \\| __|\n' +
            ' / /| |_| | | (_| |  __/ |  | | | (__| | | | |_\n' +
            '/____\\__,_|_|\\__,_|\\___|_|  |_|_|\\___|_| |_|\\__|.nl');
    }
}

let sample_basket = [];

window.addToBasket = function (name, color, product_number, name_long) {

    let sampleBasket = sessionStorage.getItem('sampleBasket');
    if (sampleBasket) {
        sample_basket = JSON.parse(sampleBasket);
    }

    let item = {
        'name': name,
        'colors': [color],
        'product_number': product_number,
        'name_long': name_long,
        'basecolor': color
    };

    if (typeof sample_basket.find((sample) => { return sample.product_number === item.product_number; }) === 'undefined') {
        sample_basket.push(item);
        sessionStorage.setItem('sampleBasket', JSON.stringify(sample_basket));
        window.location.href = '/gevelstenen/monsteraanvraag';
    } else {
        window.alert('Sample is reeds toegevoegd.');
    }
};
