import algoliasearch from 'algoliasearch/lite';

export class Collection {

    products = [];

    constructor () {
         this.getProductJSON();
        const searchClient = algoliasearch(
            'SY2ZJCLDTM',
            'e1f267f097a37f6fe1f0b0b891110d54'
        );

        this.searchindex = searchClient.initIndex('collection');

        this.formats = [];

        // Er zijn maar 3 formaten dus opgelost in code
        this.formats.push({'code': 'HF', 'name': 'HILVERSUMS FORMAAT', length: 242, width: 98, height: 40, correctionWidth: 0.98, correctionHeight: 0.95});
        this.formats.push({'code': 'WF', 'name': 'WAALFORMAAT', length: 210, width: 100, height: 50, correctionWidth: 1, correctionHeight: 1});
        this.formats.push({'code': 'BS', 'name': 'BRITISH STANDARD (DF)', length: 216, width: 98, height: 65, correctionWidth: 0.985, correctionHeight: 0.985});


    }

    /**
     * Vervang formaat en afwerkingsstrings voor codes
     * @param sizeOrFinish
     * @returns {*}
     */
    makeShort (sizeOrFinish) {
        if (!sizeOrFinish) return '';
        sizeOrFinish = sizeOrFinish.replace('Waalformaat', 'WF');
        sizeOrFinish = sizeOrFinish.replace('Wasserstrich', 'WS');
        sizeOrFinish = sizeOrFinish.replace('Handvorm', 'HV');
        sizeOrFinish = sizeOrFinish.replace('Vormbak', 'VB');
        sizeOrFinish = sizeOrFinish.replace('Hilversums formaat', 'HF');
        sizeOrFinish = sizeOrFinish.replace('BS Formaat (DF)', 'BS');
        sizeOrFinish = sizeOrFinish.replace('British Standard (DF)', 'BS');

        // Verwijder alles binnen ( en ) inclusief de haakjes
        // om dubbele formaten te voorkomen
        sizeOrFinish = sizeOrFinish.replace(/\(.*?\)/g, '');
        return sizeOrFinish;
    }

    /**
     * Haal formaten van steen op ... op basis van SizeName
     * @param sizeName
     * @returns {{}}
     */
    getProductSizeFromName (sizeName) {
        let productSize = {};
        productSize.length = 210;
        productSize.width = 100;
        productSize.height = 50;
        productSize.correctionWidth = 1;
        productSize.correctionHeight = 1;

        if (sizeName === undefined) return productSize;
        const format = this.formats.find(({name}) => name === sizeName.toUpperCase());

        if (format !== undefined) {
            productSize.length = format.length;
            productSize.width = format.width;
            productSize.height = format.height;
            productSize.correctionWidth = format.correctionWidth;
            productSize.correctionHeight = format.correctionHeight;
        }
        return productSize;
    }




    /**
     * Zoek 1 product op in algolia op basis van product_number
     * @param productNumber
     * @returns {Promise<{hits: *, exactMatch: boolean}>}
     */
    getProductFromAlgolia (productNumber) {
        return this.searchindex.search(productNumber, {
            distinct: 0,
            //attributesToRetrieve: ['product_number', 'name'],
            hitsPerPage: 1,
            //filters: 'name:wit',
        }).then(response => {

            let hits = response.hits;
            let isExactMatch = false;
            if (hits.length > 0) {
                let product = hits[0];
                if (product.product_number === productNumber) {
                    return {'product': product, 'exactMatch': true};
                } else {
                    return {'product': product, 'exactMatch': false};
                }
            }
            // Hoort hier eigenlijk niet thuis maar voor nu prima
            document.querySelector('.configurator__working').innerHTML = 'error a';
            return {'product': undefined, 'exactMatch': false};
        });
    }



    /**
     *
     * @param productNumber
     * @returns {Promise<{product: *, exactMatch: boolean}|{product: undefined, exactMatch: boolean}>}
     */
    async getProductFromJSON (productNumber) {

        if (this.products.length === 0) { await this.getProductJSON(); }
        let product = this.products.find(({product_number}) => product_number === productNumber);
        if (product !== undefined) {
            return {'product': product, 'exactMatch': true};
        } else {
            // Hoort hier eigenlijk niet thuis maar voor nu prima
            document.querySelector('.configurator__working').innerHTML = 'error j';
        }
        return {'product': undefined, 'exactMatch': false};
    }

    // Get a Random product from the product JSON list
    async getRandomProductFromJSON() {
        if (this.products.length === 0) {
            return {'product': undefined, 'exactMatch': false};
        }
        const randomSeed = Date.now();
        Math.seedrandom(randomSeed);

        const randomIndex = Math.floor(Math.random() * this.products.length);
        const randomProduct = this.products[randomIndex];
        return {'product': randomProduct, 'exactMatch': true};
    }

    getProductJSON () {
        return new Promise((resolve, reject) => {
            let self = this;
            let products = sessionStorage.getItem('products');
            if (products !== null) {
                this.products = JSON.parse(products);
            } else {
                fetch('/zl/product-list').then(response => {
                    products = response.json();
                    products.then(data => {
                        sessionStorage.setItem('products', JSON.stringify(data));
                        self.products = data;
                        resolve();
                    });
                }).catch(error => {
                    console.error(error);
                    reject();
                });
            }
        });
    }
}
