<template>
    <ais-instant-search
            :index-name="getIndexName"
            :search-client="searchClient"
    >
        <div class="site-search">

            <ais-search-box :placeholder="t('Hier zoeken ...')" v-model="query" :class-names="{'ais-SearchBox-input': 'ais-SearchBox-input main-search-input'}"></ais-search-box>
            <ais-stats>
                <template v-slot="{ hitsPerPage, nbPages, nbHits, page, processingTimeMS, query }">
                    <div class="site-search__subline" v-show="query.length > 0" v-if="nbHits > 0">
                        {{ t('results_found') }}: {{nbHits}}
                    </div>
                    <div class="site-search__subline" v-show="query.length > 0" v-else></div>
                </template>
            </ais-stats>
            <ais-infinite-hits v-show="query.length > 0">
                <template v-slot:item="{ item }">
                    <a class="site-search__link" :href="item.path">
                        <h3 class="site-search__title">{{item.title}}</h3>
                        <ais-snippet :hit="item" attribute="content"></ais-snippet>...<br/>
                        <span class="site-search__path" :href="item.path">{{ item.path }}</span>
                    </a>
                </template>
            </ais-infinite-hits>
        </div>
    </ais-instant-search>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/algolia-min.css';

    export default {
        data() {
          return {
              query : '',
              searchClient: algoliasearch(
                  this.$root.$options.siteSearchIndexConfig.applicationId,
                  this.$root.$options.siteSearchIndexConfig.searchApiKey
              ),
          }
        },
        methods: {
            t(key) {
                if (!key in this.getTranslations) {
                    return key;
                }

                return this.getTranslations[key];
            },
        },
        computed: {
            getTranslations() {
              return this.$root.$options.siteSearchTranslations;
            },
            getApplicationId() {
                return this.$root.$options.siteSearchIndexConfig.applicationId;
            },
            getApiKey() {
                return this.$root.$options.siteSearchIndexConfig.searchApiKey;
            },
            getIndexName() {
                return this.$root.$options.siteSearchIndexConfig.indexName;
            }
        },
    };
</script>
<style scoped="scoped" lang="scss">
.ais-InfiniteHits-list {
    flex-flow: column;

    .ais-InfiniteHits-item {
        width: 100%;
    }
}
</style>
