<template>
    <div class="d-none d-md-block">
        <ais-instant-search
            :index-name="getIndexNames[0]"
            :search-client="searchClient"
        >
            <ais-configure
                :hits-per-page.camel="4"
            />
            <div class="site-search" v-show="query.length > 0">

                <ais-search-box ref="extended-search" :placeholder="t('Hier zoeken ...')" style="visibility: hidden; height: 0;" v-model="query"></ais-search-box>
                <input type="hidden" @input="updateQuery" class="extended-search-input">
                <ais-infinite-hits :class-names="{'ais-InfiniteHits-list': 'ais-InfiniteHits-list MyCustomInfiniteHitsList'}">
                    <template v-slot:item="{ item }">
                        <div @click="openProduct(item)" class="rodruza-collection__info-card">
                            <div class="info-img">
                                <img loading="lazy" :src="imageUrl(item)"/>
                            </div>
                            <div class="info-line info-line--white info-line--min">
                                {{ item.classificationname }} {{ item.name }}
                            </div>
                            <div class="info-line">
                                <span style="float: right;">{{ item.product_number }}</span>
                                <strong>{{ makeShort(item.size) }} {{ makeShort(item.finish) }}</strong>
                            </div>
                        </div>
                    </template>
                </ais-infinite-hits>
                <ais-index :index-name="getIndexNames[1]">
                    <ais-infinite-hits :class-names="{'ais-InfiniteHits-list': 'ais-InfiniteHits-list MyCustomInfiniteHitsList'}">
                        <template v-slot:item="{ item }">
                            <div @click="openProject(item)" class="rodruza-projects__info-card" :class="item.is_case ? 'is-case' : ''">
                                <div class="info-img">
                                    <picture class="info-img--hover" v-if="item.project_hover_image">
                                        <source v-for="source in item.project_hover_image.sources" :srcset="source.srcset" :media="source.media">
                                        <img :src="item.project_hover_image.image.src" :alt="item.project_hover_image.image.alt">
                                    </picture>
                                    <picture v-if="item.project_image">
                                        <source v-for="source in item.project_image.sources" :srcset="source.srcset" :media="source.media">
                                        <img :src="item.project_image.image.src" :alt="item.project_image.image.alt">
                                    </picture>
                                </div>
                                <div class="info-line info-line--white">
                                    {{ item.name }}
                                </div>
                                <div class="info-line info-line--white">
                                    {{ item.city }}
                                </div>
                            </div>
                        </template>
                    </ais-infinite-hits>
                </ais-index>
            </div>
        </ais-instant-search>
    </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/algolia-min.css';

export default {
    data() {
        return {
            query: '',
            searchClient: algoliasearch(
                this.$root.$options.siteSearchIndexConfig.applicationId,
                this.$root.$options.siteSearchIndexConfig.searchApiKey
            ),
        }
    },
    methods: {
        t(key) {
            if (!key in this.getTranslations) {
                return key;
            }

            return this.getTranslations[key];
        },
        makeShort(sizeOrFinish) {
            return window.Collection.makeShort(sizeOrFinish);
        },
        imageUrl(item) {
            return this.imageUrlByCode(item.product_number);
        },
        imageUrlByCode(code) {
            return "/application/files/rodruza/products/brickRenders/" + code + "/render.jpeg";
        },
        updateQuery(event) {
            this.query = event.currentTarget.value;
        },
        openProduct(item) {
            window.location.href = '/gevelstenen/product/' + item.product_number;
        },
        openProject(item) {
            window.location.href = '/inspiratie/project?query=' + item.name + '&open=true';
        },
    },
    computed: {
        getTranslations() {
            return this.$root.$options.siteSearchTranslations;
        },
        getApplicationId() {
            return this.$root.$options.siteSearchIndexConfig.applicationId;
        },
        getApiKey() {
            return this.$root.$options.siteSearchIndexConfig.searchApiKey;
        },
        getIndexNames() {
            return this.$root.$options.siteSearchIndexConfig.indexNames;
        },
    },
};
</script>
<style lang="scss">
.MyCustomInfiniteHitsList {
    position: relative;
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    gap: 10px;

    .ais-InfiniteHits-item {
        padding: 0 !important;
    }
}
</style>
